import { styled } from "styled-components";
import { baseTheme } from "styles/theme";

export const SubContainer = styled.div`
`
export const SubButton = styled.div`
width: '100%';
padding-top: 10px;
`

export const Row = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
`
export const LinkButton = styled.a`
  color: ${baseTheme.colors.link};
  cursor: pointer;
  text-align: left;
  font-size: 15px;
  line-height: 1.7;
  font-family: "Poppins", sans-serif;
  text-decoration: underline;
`;

export const Text = styled.p`
  font-family: "Poppins", sans-serif;
  color: #222222;
  text-align: left;
  font-size: 16px;
  line-height: 1.7;
  width: 350px;
  word-wrap: break-word;
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;