import styled from "styled-components";
import { ChangeEvent } from "react";
import { baseTheme } from "styles/theme";

interface ISearch {
  searchWord: string;
  setSearchWord: (tab: string) => void;
}

const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  width: 100%;
  border-radius: 49px;
  font-family: "Poppins", sans-serif;
  padding: 0.75rem;
  font-size: 0.75rem;
  height: 45px;
  border-color: transparent;

  line-height: 1;
  background-color: ${baseTheme.colors.gray};
  &:focus {
    outline: 0 none;
    transition-duration: 0.3s;
    border-color: ${baseTheme.colors.border};
  }
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;

function Search({ searchWord, setSearchWord }: ISearch) {
  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setSearchWord(event.target.value);
  }

  return (
    <SearchWrapper>
      <StyledInput
        name="search"
        type="text"
        placeholder="Search"
        value={searchWord}
        onChange={handleChange}
      />
    </SearchWrapper>
  );
}

export default Search;
