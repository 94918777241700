/* eslint-disable no-restricted-globals */
import React, { useMemo, useRef, useState } from "react";
import styled from "styled-components";
import AuthCode, { AuthCodeRef } from "react-auth-code-input";
import { baseTheme } from "styles/theme";
import { StyledH3 } from "styles/components";
import { StyledBoldText, StyledRow, StyledSpanText } from "./styles";
import Button from "../Buttons/Button";
import { useAxios } from "hooks/useAxios";
import useAuthStore from "store/authStore";
import useSignUpStore from "store/signUpData";
import { PhoneNumberMask, getUserRole, getUserType, saveToken } from "helpers";
import { useNavigate } from "react-router-dom";

const StyledAuthCodeWrapper = styled.div`
  width: 300px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  input {
    border: 1px solid ${baseTheme.colors.main};
    width: 35px;
    padding: 10px;
    text-align: center;
    margin: 12px;
    border-radius: ${baseTheme.sizes.radius.authCode}px;
    font-size: 1rem;
    line-height: 1;
  }
  @media (min-width: 1921px) {
    width: 450px;
  }
`;
const TwoStepFormStyled = styled.form``;

function TwoStepForm() {
  const type = getUserType();
  const statusRole = getUserRole();
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const navigate = useNavigate();
  const [result, setResult] = useState<string>();
  const { accessToken, phone } = useAuthStore();
  const clearSignUpState = useSignUpStore((state) => state.clearSignUpState);
  const { first_name, last_name, phoneSignUp, email, password, token } =
    useSignUpStore();
  const { request, loading } = useAxios();
  const handleOnChange = (res: string) => {
    setResult(res);
  };

  async function twoStepCheck(event: any) {
    event.preventDefault();
    if (phoneSignUp) {
      twoStepCheckSignUp();
    } else {
      twoStepCheckLogin();
    }
  }
  const buttonDisabled = useMemo(() => {
    return !result;
  }, [result]);
  async function twoStepCheckSignUp() {
    try {
      const res = await request("auth/check-phone/check", "POST", {
        token: token,
        code: result,
      });

      if (res.message === "Phone checked.") {
        const res = await request("auth/register", "POST", {
          first_name,
          last_name,
          email,
          password,
          token,
          device_id: null,
        });
        if (res.access_token) {
          clearSignUpState();
          navigate("/confirm-account");
        }
      }
    } catch (e) {}
  }
  async function getAgainCode() {
    await request("auth/2fa", "GET", null, {
      Authorization: `Bearer ${accessToken}`,
    });
  }
  async function twoStepCheckLogin() {
    try {
      const res = await request(
        "auth/2fa",
        "POST",
        {
          code: result,
        },
        {
          Authorization: `Bearer ${accessToken}`,
        }
      );
      saveToken(accessToken);
      if (
        res.message === "2FA code successful checked." &&
        (type === "consultant" ||
          type === "set-trainee" ||
          type === "unaccredited-trainee")
      ) {
        useAuthStore.getState().setAuthenticated(true);
        if (statusRole === "superadmin" || statusRole === "admin") {
          navigate("/users");
        } else {
          navigate("/case-logs");
        }
      }
      if (res.message === "2FA code successful checked." && type === null) {
        navigate("/user-settings");
      }
    } catch (e) {}
  }

  return (
    <StyledAuthCodeWrapper>
      <StyledH3>Enter verification code</StyledH3>
      <StyledBoldText>
        Enter code that we have sent to your number{" "}
        {phone && PhoneNumberMask(phone)}
        {phoneSignUp && PhoneNumberMask(phoneSignUp)}
      </StyledBoldText>
      <TwoStepFormStyled onSubmit={twoStepCheck}>
        <AuthCode length={4} onChange={handleOnChange} ref={AuthInputRef} />
        <Button disabled={buttonDisabled} loading={loading} text="Verify" />
      </TwoStepFormStyled>
      <StyledRow>
        <StyledSpanText>Did not receive the code?</StyledSpanText>
        <Button
          onClick={getAgainCode}
          color={baseTheme.colors.main}
          text="Resend"
          as="a"
        />
      </StyledRow>
    </StyledAuthCodeWrapper>
  );
}

export default TwoStepForm;

