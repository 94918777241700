import { LinkButton, Text } from "./subManagerStyles";
import { getFromLocalStorageUser, IUserLStorage } from "helpers";

export default function IOSTextSub() {
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { subscribe_expire_date } = user;
  return (
    <>
      <Text>Your Subscription is active.</Text>
      <Text>Extension date is {subscribe_expire_date}.</Text>
      <Text>Your subscription is managed via AppStore account.</Text>
      <Text>
        To unsubscribe please visit your{" "}
        <LinkButton href="https://www.apple.com/app-store/" target="_blank">
          App Store account.
        </LinkButton>
      </Text>

      <Text>
        More information can be found{" "}
        <LinkButton
          href="https://support.apple.com/en-us/118428"
          target="_blank"
        >
          here.
        </LinkButton>
      </Text>
    </>
  );
}
