import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import AddCustomFields from "./AddCustomFields";
import { baseTheme } from "styles/theme";
import { EditButton } from "../styles";
import Button from "components/Buttons/Button";
import { TitleSection } from "styles/components";
import { SettingsArrayType } from "interfaces";
import CustomField from "./CustomField";

interface CustomFieldData {
  id: string;
  name: string;
  type: string;
  "custom-field-values": [];
}
interface ICustomFieldsList {
  dataSettingsProfile: SettingsArrayType;
  settingsProfile: () => {};
}
const TitleWrapper = styled.div`
  @media (max-width: 900px) {
    padding-top: 50px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CustomFieldsList = ({
  dataSettingsProfile,
  settingsProfile,
}: ICustomFieldsList) => {
  const [show, toggle] = useReducer((show) => !show, false);
  const [customFields, setCustomFields] = useState<CustomFieldData[]>([]);
  const [data, setData] = useState<CustomFieldData | null>(null);
  const [showCustomModal, setShowCustomModal] = useState(false);
  useEffect(() => {
    setCustomFields(dataSettingsProfile[0]?.custom_fields);
  }, [dataSettingsProfile]);

  const customConfig = [
    {
      title: "Add custom fields",
      id: "Add custom fields",
      onPress: () => toggle(),
      color: baseTheme.colors.main,
          bg: baseTheme.colors.transparent,
      showItem: Array.isArray(customFields) && customFields.length < 6,
    },
    ...(Array.isArray(customFields)
      ? customFields.map((field) => ({
          title: field?.name || "N/A",
          id: field?.id,
          color: baseTheme.colors.white,
          bg: baseTheme.colors.main,
          onPress: () => handleFieldPress(field?.id),
          showItem: true,
        }))
      : []),
  ];

  const handleFieldPress = async (fieldId: string) => {
    const customField: any = customFields.filter((item) => item.id === fieldId);
    setData(customField[0]);
    setShowCustomModal(true);
  };

  return (
    <Container>
      <TitleWrapper>
        <TitleSection>Custom fields</TitleSection>
      </TitleWrapper>

      {customConfig.map(
        ({ title, onPress, id, showItem, bg, color }) =>
          showItem && (
            <EditButton key={id}>
              <Button
                bg={bg}
                br={color}
                color={color}
                onClick={onPress}
                text={title}
              />
            </EditButton>
          )
      )}
      <AddCustomFields
        getCustomFields={settingsProfile}
        showModal={show}
        dataSettingsProfile={dataSettingsProfile}
        onCloseModal={toggle}
      />
      {customFields && (
        <CustomField
          getCustomFields={settingsProfile}
          data={data}
          setData={setData}
          showModal={showCustomModal}
          onCloseModal={() => setShowCustomModal(false)}
        />
      )}
    </Container>
  );
};

export default CustomFieldsList;
