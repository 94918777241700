import {
  IUserLStorage,
  getFromLocalStorageUser,
  getStatusMember,
  getToken,
} from "helpers";
import styled from "styled-components";
import { baseTheme } from "styles/theme";
import { useAxios } from "hooks/useAxios";
import { useReducer,  } from "react";
import { StyledBoldText } from "components/Auth/styles";
import MobileOutlined from "@ant-design/icons/MobileOutlined";
import MailOutlined from "@ant-design/icons/MailOutlined";
import User from "assets/icons/User";
import ProfileUserType from "./ProfileUserType";
import UserIcon from "assets/icons/User";
import DeleteUserAccount from "assets/icons/DeleteUserAccount";
import DeleteUserAccountModal from "pages/Profile/components/DeleteUserAccount";
import SubscriptionManager from "./SubscriptionManager/SubscriptionManager";
const ProfileUserInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  max-width: 400px;
  padding: 20px;
  margin: 0 auto;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  @media (min-width: 1921px) {
    max-width: 600px;
  }
`;

const DeleteAccount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  max-width: 400px;
  width: 100%;
  padding: 15px 10px;

  margin: 0 auto;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  @media (min-width: 1921px) {
    margin-top: 20px;
    max-width: 460px;
  }
  @media (max-width: 1024px) {
    margin: 20px auto;
  }
`;

const ProfileUseRouterContainer = styled.div`
  width: 50%;
  height: 100%;
  padding-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 900px) {
    border-right: none;
    padding-bottom: 50px;
  }
`;

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(242, 242, 242);
  width: 130px;
  height: 130px;
  border-radius: 150px;
  margin-bottom: 20px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 1921px) {
    max-width: 600px;
  }
`;
const Letters = styled.p`
  font-size: 50px;
  color: ${baseTheme.colors.main};
  font-family: "Poppins", sans-serif;
`;
export const UserInfoRow = styled.div`
  flex-direction: row;
  display: flex;
  padding-left: 20px;
  align-items: center;
  margin-bottom: 15px;
`;

export const Text = styled.p`
  font-family: "Poppins", sans-serif;
  color: #222222;
  text-align: left;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 500;
  width: 350px;
  word-wrap: break-word;
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;
const TextSubscribe = styled.p`
  font-family: "Poppins", sans-serif;
  color: #222222;
  text-align: left;
  font-size: 15px;
  line-height: 1.7;
  flex: 1;
  font-weight: 500;
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;
export const IconBubble = styled.div`
  background-color: rgb(242, 242, 242);
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50px;
  margin-right: 10px;
`;

export const ButtonSubscribe = styled.button<{
  textDecoration?: string;
  color?: string;
}>`
  border-radius: 8px;
  padding: 0;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  justify-content: center;
  color: ${({ color }) => color || "#007AFF"};
  font-weight: 500;
  font-size: 16px;
  border: none;
  text-decoration: ${({ textDecoration }) => textDecoration || "underline"};
  background-color: transparent;

  @media (min-width: 1921px) {
    font-size: 18px;
  }

  @media (max-width: 900px) {
    align-items: center;
  }
`;

interface IProfileUserinfo {
  settingsProfile?: () => void;
}

function ProfileUserinfo({ settingsProfile }: IProfileUserinfo) {
  const statusMember = getStatusMember();
  const token = getToken();

  const [showDeleteModal, toggleDeleteModal] = useReducer(
    (showDeleteModal) => !showDeleteModal,
    false
  );
  const [showUserType, toggleUserType] = useReducer(
    (showUserType) => !showUserType,
    false
  );
  const [showSubManager, toggleSubManager] = useReducer(
    (showUserType) => !showUserType,
    false
  );

  const { request } = useAxios();
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { first_name, role, last_name, email, phone, subscribe_status, type } =
    user;

  async function unSubscribe() {
    toggleSubManager();
    await request("stripe/cancel-subscription-at-period-end", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
  }

  async function confirmHandlePayment() {
    if (statusMember === "subscribed") {
      unSubscribe();
    }
    if (statusMember === "demo" || "demo-expired" || "subscribe-expired") {
      const res = await request("stripe/generate-payment-link", "GET", null, {
        Authorization: `Bearer ${token}`,
      });
      if (res) {
        window.location.href = res.url;
        toggleSubManager();
      }
    }
  }

  function translateData(data: string) {
    switch (data) {
      case "set-trainee":
        return "SET trainee";
      case "consultant":
        return "consultant";
      case "unaccredited-trainee":
        return "unaccredited trainee";
    }
  }

  return (
    <>
      {first_name && last_name && (
        <>
          <ProfileUseRouterContainer>
            <ProfileUserInnerContainer>
              <Avatar>
                <Letters>{first_name.slice(0, 1) || null}</Letters>
                <Letters>{last_name.slice(0, 1) || null}</Letters>
              </Avatar>
              <Content>
                <UserInfoRow>
                  <IconBubble>
                    <User
                      width={20}
                      height={20}
                      color={baseTheme.colors.main}
                    />
                  </IconBubble>
                  <Text>
                    {first_name} {last_name}
                  </Text>
                </UserInfoRow>
                <UserInfoRow>
                  <IconBubble>
                    <MailOutlined
                      style={{ fontSize: "20px", color: baseTheme.colors.main }}
                    />
                  </IconBubble>
                  <Text>{email}</Text>
                </UserInfoRow>
                <UserInfoRow>
                  <IconBubble>
                    <MobileOutlined
                      style={{ fontSize: "20px", color: baseTheme.colors.main }}
                    />
                  </IconBubble>
                  <Text>{phone}</Text>
                </UserInfoRow>
                {role !== "superadmin" && (
                  <>
                    <StyledBoldText>
                      <TextSubscribe>
                        <span style={{ paddingRight: "5px" }}>
                          Your type of user:
                          <span style={{ fontWeight: "600" }}>
                            {" "}
                            {translateData(type)}.
                          </span>
                        </span>
                        <span>To change the user type </span>
                        <ButtonSubscribe
                          textDecoration="text"
                          color="#000"
                          onClick={toggleUserType}
                        >
                          click here.
                        </ButtonSubscribe>
                      </TextSubscribe>
                    </StyledBoldText>
                    <StyledBoldText>
                      <TextSubscribe>
                        <span style={{ paddingRight: "5px" }}>
                          Your subscription status:
                          <span style={{ fontWeight: "600" }}>
                            {subscribe_status}.
                          </span>
                        </span>
                        <span>To manage your subscription </span>
                        <ButtonSubscribe
                          textDecoration="text"
                          color="#000"
                          onClick={toggleSubManager}
                        >
                          click here.
                        </ButtonSubscribe>
                      </TextSubscribe>
                    </StyledBoldText>
                  </>
                )}
              </Content>
            </ProfileUserInnerContainer>
            <div style={{ width: "100%" }}>
              <DeleteAccount>
                <IconBubble>
                  <DeleteUserAccount color={baseTheme.colors.error} />
                </IconBubble>
                <ButtonSubscribe
                  textDecoration="text"
                  color={baseTheme.colors.error}
                  onClick={toggleDeleteModal}
                >
                  Delete user account
                </ButtonSubscribe>
              </DeleteAccount>
            </div>
          </ProfileUseRouterContainer>
          <DeleteUserAccountModal
            isOpen={showDeleteModal}
            onRequestClose={toggleDeleteModal}
          />
          <ProfileUserType
            icon={
              <UserIcon color={baseTheme.colors.main} width={32} height={32} />
            }
            title={"Select user type"}
            isOpen={showUserType}
            settingsProfile={settingsProfile}
            onRequestClose={toggleUserType}
          />
          <SubscriptionManager
            confirmHandlePayment={confirmHandlePayment}
            isOpen={showSubManager}
            onRequestClose={toggleSubManager}
          />
        </>
      )}
    </>
  );
}

export default ProfileUserinfo;
