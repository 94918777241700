import React from "react";
import { CiLogout } from "react-icons/ci";
import { baseTheme } from "styles/theme";
import { useNavigate } from "react-router-dom";
import logOut, { getUserRole } from "helpers";

import {
  ButtonLogout,
  Column,
  LogoutText,
  SidebarContainer,
  Title,
  TitleWrap,
} from "./styles";
import SuperAdminTabs from "./SuperAdminTabs";
import AdminTabs from "./AdminTabs";
import UserTabs from "./UserTabs";
import Logo from "assets/icons/Logo";

function Sidebar() {
  const navigate = useNavigate();
  const userRole = getUserRole();

  let formComponent;

  switch (userRole) {
    case "superadmin":
      formComponent = <SuperAdminTabs />;
      break;
    case "admin":
      formComponent = <AdminTabs />;
      break;
    case "user":
      formComponent = <UserTabs />;
      break;
  }
  function onLogOut() {
    logOut();
    navigate("/");
  }
  return (
    <SidebarContainer>
      <Column>
        <Logo flag={"main"} />
        <TitleWrap>
          <Title>Snap Log</Title>
        </TitleWrap>

        {formComponent}
      </Column>
      <ButtonLogout onClick={onLogOut}>
        <LogoutText>Logout</LogoutText>
        <CiLogout fontSize={24} color={baseTheme.colors.main} />
      </ButtonLogout>
    </SidebarContainer>
  );
}

export default Sidebar;
