import styled, { keyframes } from "styled-components";
import Button from "components/Buttons/Button";
import { baseTheme } from "styles/theme";
import { OperationCase } from "interfaces";
import { useNavigate } from "react-router";

interface IModalPathology {
  item: OperationCase;
  closeModal: () => void;
  checkPathology: () => void;
  addOutcome: (rowData: any) => void;
  isOpen: boolean;
}
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const TextWrapper = styled.div`
  color: #222222;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  padding-bottom: 10px;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 28px;
  }
`;
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
const ModalContainer = styled.div`
  background: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 24px;
  animation: ${fadeIn} 0.3s ease;
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  padding-bottom: 10px;
  width: 100%;
`;

function ModalPathology({
  checkPathology,
  item,
  closeModal,
  isOpen,
}: IModalPathology) {
  const navigate = useNavigate();
  const title = item?.path_check ? "Uncheck pathology" : "Check pathology";
  if (!isOpen) {
    return null;
  }

  return (
    <Overlay>
      <ModalContainer>
        <TextWrapper>
          Pathology for {item.patient.first_name} {item.patient.last_name} DOB{" "}
          {item.patient.date_of_birth}
        </TextWrapper>
        <ButtonWrapper>
          <Button onClick={checkPathology} text={title} />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            onClick={() => {
              localStorage.setItem("reviewId", item.id.toString());
              navigate("/review", { state: { isOpenPathModal: true } });
            }}
            text={"Add pathology outcome"}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            br={baseTheme.colors.main}
            bg={"transparent"}
            as="button"
            color={baseTheme.colors.main}
            onClick={closeModal}
            text="Cancel"
          />
        </ButtonWrapper>
      </ModalContainer>
    </Overlay>
  );
}

export default ModalPathology;
