import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import { baseTheme } from "styles/theme";
import Button from "./Buttons/Button";
import Toast from "./Toast";

interface IHospitalDropdown {
  show?: boolean;
}

const HospitalInput = styled.input`
  position: relative;
  width: 100%;
  padding: 0.75rem;
  font-size: 14px;
  border: 1px solid ${baseTheme.colors.gray};
  border-radius: 49px;
  background-color: ${baseTheme.colors.gray};

  &::placeholder {
    color: #b4b4b4 !important;
  }
  &:focus-within {
    border: 1px solid ${baseTheme.colors.gray};
  }
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;
const HospitalDropdown = styled.div<IHospitalDropdown>`
  position: absolute;
  width: 100%;
  z-index: 10;
  max-width: 350px;
  max-height: 200px;
  overflow-y: auto;
  background-color: ${baseTheme.colors.gray};
  border-radius: ${baseTheme.sizes.radius.input}px;
  border-top: none;
  display: ${(props) => (props.show ? "block" : "none")};
  @media (min-width: 1921px) {
    max-height: 300px;
    max-width: 450px;
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 1em;
  padding-bottom: 20px;
`;
const HospitalOption = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;
const Form = styled.form`
  width: 100%;
  position: relative;
  z-index: 0;
`;
interface IHospital {
  id: number;
  name: string;
  remoteness_area: string;
  state: string;
}

interface IHospitalsDropdown {
  settingsProfile: () => void;
}

function HospitalsDropdown({
  settingsProfile,
}: IHospitalsDropdown): JSX.Element {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const token = getToken();
  const { request } = useAxios();
  const [hospitals, setHospitals] = useState<IHospital[]>();
  const [inputValue, setInputValue] = useState<string>("");
  const [currentHospitalId, setCurentHospitalId] = useState<number | null>(
    null
  );
  const [filteredHospitals, setFilteredHospitals] = useState<IHospital[]>([]);
  const [error, setError] = useState("");
  useEffect(() => {
    getHospitalsList();
    // eslint-disable-next-line
  }, []);

  async function getHospitalsList(): Promise<void> {
    const res = await request("profile/hospital/list", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    setHospitals(res?.data);
  }
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (currentHospitalId === null) {
      setError("Select a hospital from the list");
    }
    try {
      await request(
        `profile/hospital`,
        "POST",
        {
          hospital_list_id: currentHospitalId,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      await settingsProfile();
      setInputValue("");
      setCurentHospitalId(null);
    } catch (e) {}
  }
  function filterHospitals(searchText: string): void {
    const filtered = hospitals!.filter((hospital: IHospital) =>
      hospital.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setIsDropdownVisible(true);
    setFilteredHospitals(filtered);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const value: string = event.target.value;
    if (hospitals) {
      filterHospitals(value);
      setInputValue(value);
    }
  }

  function handleHospitalSelection(hospital: IHospital): void {
    setInputValue(hospital.name);
    setCurentHospitalId(hospital.id);
    setIsDropdownVisible(false);
  }
  const ButtonDisabled = useMemo(() => {
    return !inputValue;
  }, [inputValue]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <HospitalInput
          type="text"
          name="newValue"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter hospital"
        />
        <HospitalDropdown show={isDropdownVisible}>
          {filteredHospitals.map((hospital: IHospital) => (
            <HospitalOption
              key={hospital.id}
              onClick={() => handleHospitalSelection(hospital)}
            >
              {hospital.name}
            </HospitalOption>
          ))}
        </HospitalDropdown>
        <ButtonWrapper>
          <Button
            fontWeight={600}
            color={baseTheme.colors.white}
            fontSize="14px"
            disabled={ButtonDisabled}
            text="Save"
          />
        </ButtonWrapper>
      </Form>
      {error && <Toast error={error} />}
    </>
  );
}

export default HospitalsDropdown;
