import { IIcon } from "interfaces";

const Subscription = ({ color, width = 28, height = 28 }: IIcon) => {
  return (
    <div style={{ width: width, height: height }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M8 2v4" />
        <path d="M16 2v4" />
        <rect width="18" height="18" x="3" y="4" rx="2" />
        <path d="M3 10h18" />
        <path d="m9 16 2 2 4-4" />
      </svg>
    </div>
  );
};

export default Subscription;
