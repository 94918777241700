import { styled } from "styled-components";
import { baseTheme } from "styles/theme";

interface IEDButtons {
  isHospitals?: boolean;
}

export const EDButtons = styled.div<IEDButtons>`
  display: flex;
  justify-content: ${(props) =>
    props.isHospitals ? "flex-end" : "space-between"};

  align-items: center;
  width: 60px;
`;
export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ButtonWrapper = styled.div`
  padding-bottom: 10px;
  width: 100%;
`;

export const FormCustomField = styled.form`
  width: 100%;
  padding-top: 10px;
`;

export const DefultSpan = styled.span`
  color: ${baseTheme.colors.black};
  text-align: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
`;
export const UpdateModalInfoWrapper = styled.div`
  /* padding: 10px; */
  /* width: 350px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 400px;
  @media (min-width: 1921px) {
    /* width: 450px; */
  }
`;

export const CloseIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 14px;
    background-color: #fff;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

export const CloseButton = styled.button`
  background-color: ${baseTheme.colors.error};
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const EditButton = styled.div`
  cursor: pointer;
  padding-top: 5px;
`;
export const List = styled.ul`
  /* width: 350px; */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  @media (min-width: 1921px) {
    /* width: 450px; */
  }
`;
export const ItemList = styled.li`
  padding: 10px 10px 10px 0;
  margin-right: -5px;
  flex-direction: row;
  align-items: center;
  list-style: none;
  display: flex;
  line-height: 20px;
  font-size: 0.9rem;
  flex-direction: row;
  font-family: "Poppins", sans-serif;
  justify-content: space-between;
  border-bottom: 1px solid #d9dddc;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const EditInput = styled.input`
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  padding: 4px;
  font-size: 0.9rem;
  font-weight: 500;
  /* border-bottom: 1px solid ${baseTheme.colors.main}; */
  @media (min-width: 1921px) {
    font-size: 18px;
  }
`;
export const SCButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  @media (min-width: 1921px) {
    width: auto;
  }
`;

export const Container = styled.div`
  position: relative;
  z-index: 90;
  /* padding: 0 20px; */
  width: 100%;
`;

export const FieldContainer = styled.div`
  padding: 20px 0 40px;
`;
