import { useState } from "react";

import EditIcon from "assets/icons/EditIcon";
import { baseTheme } from "styles/theme";
import {
  EditInput,
  SCButtons,
  EDButtons,
  EditButton,
  CloseButton,
  CloseIcon,
  ItemList,
} from "./style";
import Cancel from "assets/icons/Cancel";
import CheckIcon from "assets/icons/CheckIcon";

interface EditableFieldProps {
  id: string;
  value: string;
  onSave: (newValue: string, id?: string) => Promise<void>;
  onDelete: (id: string) => void;
  showButton: boolean;
}

const EditableField = ({
  showButton,
  id,
  value,
  onSave,
  onDelete,
}: EditableFieldProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);

  const handleSave = async () => {
    onSave(editValue, id);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditValue(value);
    setIsEditing(false);
  };

  return (
    <ItemList>
      {isEditing ? (
        <>
          <EditInput
            type="text"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            autoFocus
          />
          <SCButtons>
            <div onClick={handleSave}>
              <CheckIcon
                color={baseTheme.colors.black}
                width={24}
                height={24}
              />
            </div>
            <div onClick={handleCancel}>
              <Cancel color={baseTheme.colors.black} width={20} height={20} />
            </div>
          </SCButtons>
        </>
      ) : (
        <>
          {value}
          <EDButtons isHospitals={!showButton}>
            <EditButton onClick={() => setIsEditing(true)} role="button">
              <EditIcon color={baseTheme.colors.black} />
            </EditButton>
            {showButton && (
              <CloseButton onClick={() => onDelete(id)}>
                <CloseIcon />
              </CloseButton>
            )}
          </EDButtons>
        </>
      )}
    </ItemList>
  );
};
export default EditableField;
