import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import Skeleton from "react-loading-skeleton";
import FileInput from "components/FileInputs";
import { useAxios } from "hooks/useAxios";
import Toast from "components/Toast";
import { IUserLStorage, getFromLocalStorageUser, getToken } from "helpers";
import axios, { AxiosResponse } from "axios";
import { baseTheme } from "styles/theme";
import InformModal from "components/Modal/ConfirmModal";

interface IImage {
  url: string;
  name: string;
  id: number;
}

const OperationNote = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  @media (max-width: 600px) {
  }
`;

const ScrollableImageWrapper = styled.div`
  height: 78vh;
  overflow-y: auto;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FileInputWrapper = styled.div`
  display: flex;
  margin-top: 30px;
`;
const PhotoViewFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
const ImageWrap = styled.div`
  height: 400px;
  width: auto;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

const ImageContainer = styled.div`
  position: relative;
  height: 450px;
  cursor: pointer;

  border-radius: 8px;
  margin: 10px;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
`;
const CloseIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 14px;
    background-color: #fff;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;

const CloseButton = styled.button`
  background-color: ${baseTheme.colors.error};
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
`;

async function fetchWithAuthentication(url: string, authToken: string) {
  const headers = {
    Authorization: `Bearer ${authToken}`,
  };

  const response: AxiosResponse<ArrayBuffer> = await axios.get(url, {
    headers,
    responseType: "arraybuffer",
  });

  return response;
}

function OperationNotes() {
  const [showPreview, setShowPreview] = useState(false);
  const [showModal, toggleModal] = useReducer((showModal) => !showModal, false);
  const { request, error, loading } = useAxios();
  const [loadingOpNotes, setLoadingOpNotes] = useState(true);
  const [images, setImages] = useState<string[]>([]);
  const [previews, setPreviews] = useState<File[]>([]);
  const [deleteItemId, setDeleteItemId] = useState();
  const [previewsModal, setPreviewsModal] = useState<string[]>([]);
  const token = getToken();
  const [countSkeleton, setSkeleton] = useState<number>(0);
  const id = localStorage.getItem("reviewId");
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { role } = user || {};
  useEffect(() => {
    getOpNotes();
    // eslint-disable-next-line
  }, []);

  const handleFileInputChange = (selectedFiles: File[] | null) => {
    if (selectedFiles !== null) {
      setPreviews(selectedFiles);
    }
  };
  const operationCaseRoute =
    role === "user"
      ? `operation-case/${id}`
      : `admin/user/2/operation-case/${id}`;
  async function getOpNotes() {
    try {
      setLoadingOpNotes(true);
      const res = await request(operationCaseRoute, "GET", null, {
        Authorization: `Bearer ${token}`,
      });

      if (res.data.images) {
        setSkeleton(res.data.images.length);
        try {
          const requests = res.data.images.map(async (image: IImage) => {
            const response = await fetchWithAuthentication(image.url, token!);
            const base64 = arrayBufferToBase64(response.data);
            const dataUrl = `data:image/png;base64,${base64}`;
            return { id: image.id, url: dataUrl };
          });

          const imagesData = await Promise.all(requests);
          setImages(imagesData);
          setLoadingOpNotes(false);
          setPreviewsModal([]);
        } catch (error) {
          console.error("Error fetching images:", error);
        }
      }
    } catch (e) {
      console.error("Error fetching operation notes:", e);
    }
  }

  async function addPhotos() {
    const formData = new FormData();
    previews.forEach((image, index) => {
      const fieldName = `files[${index}]`;
      formData.append(fieldName, image);
    });
    try {
      const res = await request(
        `operation-case/${id}/photo`,
        "POST",
        formData,
        {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      );
      setShowPreview(false);
      if (res) {
        getOpNotes();
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  const handleDelete = async () => {
    await request(
      `operation-case/${id}/photo/${deleteItemId}/delete`,
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    toggleModal();
    getOpNotes();
  };

  function arrayBufferToBase64(buffer: ArrayBuffer) {
    const binary = new Uint8Array(buffer).reduce(
      (binaryString, byte) => binaryString + String.fromCharCode(byte),
      ""
    );
    return btoa(binary);
  }

  return (
    <>
      <OperationNote>
        <PhotoProvider>
          <ScrollableImageWrapper>
            <ImageWrapper>
              {loadingOpNotes ? (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {[...Array(countSkeleton)].map((_, index) => (
                    <Skeleton
                      key={index}
                      width={250}
                      height={350}
                      style={{
                        margin: "10px",
                        maxWidth: "100%",
                        height: "auto",
                        borderRadius: "8px",
                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                      }}
                    />
                  ))}
                </div>
              ) : (
                images.map((item: any) => (
                  <ImageContainer key={item.id}>
                    <ImageWrap>
                      <PhotoView src={item.url}>
                        <Image src={item.url} alt="" />
                      </PhotoView>
                    </ImageWrap>
                    <PhotoViewFooter>
                      <CloseButton
                        onClick={() => {
                          setDeleteItemId(item.id);
                          toggleModal();
                        }}
                      >
                        <CloseIcon />
                      </CloseButton>
                    </PhotoViewFooter>
                  </ImageContainer>
                ))
              )}
            </ImageWrapper>
          </ScrollableImageWrapper>
        </PhotoProvider>
        <FileInputWrapper>
          <FileInput
            loading={loading}
            previewsModal={previewsModal}
            setPreviewsModal={setPreviewsModal}
            showPreview={showPreview}
            link={false}
            setShowPreview={setShowPreview}
            addPhotos={addPhotos}
            onChange={handleFileInputChange}
            title="Add photos"
            isIcon
          />
        </FileInputWrapper>
      </OperationNote>
      {error && <Toast error={error} />}
      <InformModal
        isOpen={showModal}
        cancelButton={toggleModal}
        confirmButton={handleDelete}
        title={"Please confirm deleting image"}
      />
    </>
  );
}

export default OperationNotes;
