import Button from "components/Buttons/Button";
import { getUserType } from "helpers";
import styled from "styled-components";
import { StyledH3 } from "styles/components";
import { baseTheme } from "styles/theme";

const UserTypeContainer = styled.div`
  padding: 20px 20px 0;

  width: 100%;
  min-width: 300px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`;

interface IUserType {
  onPress: (value: string) => void;
  title?: string;
  buttons: { text: string; value: string }[];
}

function UserType({ onPress, title, buttons }: IUserType) {
  const userType = getUserType();

  return (
    <UserTypeContainer>
      {title && <StyledH3>{title}</StyledH3>}
      {buttons.map((button, index) => (
        <ButtonWrapper key={index}>
          <Button
            bg={
              userType === button.value ? baseTheme.colors.main : "transparent"
            }
            color={
              userType === button.value
                ? baseTheme.colors.white
                : baseTheme.colors.main
            }
            onClick={() => onPress(button.value)}
            as="button"
            br={baseTheme.colors.main}
            text={button.text}
          />
        </ButtonWrapper>
      ))}
    </UserTypeContainer>
  );
}

export default UserType;
