import Modal from "components/Modal/Modal";
import { baseTheme } from "styles/theme";

import React, { useState } from "react";
import styled from "styled-components";
import { MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getToken } from "helpers";
import Button from "components/Buttons/Button";
import logOut from "helpers";
import { useAxios } from "hooks/useAxios";
import Warn from "assets/icons/Warn";

const ModalContainer = styled.div`
  padding: 20px;
  border-radius: 8px;
  width: 380px;
  max-height: 80vh;
  overflow-y: auto;
  @media (min-width: 1921px) {
    width: 450px;
  }
`;

const ContainerText = styled.div`
  margin-bottom: 15px;
`;

const Text = styled.p`
  text-align: left;
  font-size: 15px;
  line-height: 1.7;
  font-family: "Poppins", sans-serif;
  color: #333;
  margin: 5px 0;
`;

const List = styled.div`
  padding-left: 20px;
`;

const ListItem = styled(Text)`
  padding-top: 5px;
`;

const ConfirmSection = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
`;

const RoundButton = styled.div`
  background-color: ${baseTheme.colors.gray};
  border-radius: 50%;
  padding: 15px;
`;

const WrapperButton = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
`;

interface IDeleteUserAccount {
  isOpen: boolean;
  onRequestClose: () => void;
}
interface IDeletePersonComponent {
  setConfirmDeleting: (value: boolean) => void;
  confirmDeleting: boolean;
}

const DeletePersonComponent = ({
  setConfirmDeleting,
  confirmDeleting,
}: IDeletePersonComponent) => {
  const token = getToken();
  const navigate = useNavigate();

  const { request, loading } = useAxios();

  const handleConfirmClick = () => {
    setConfirmDeleting(true);
  };

  const handleDelete = async () => {
    await request("profile/user/delete", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    logOut();
    navigate("/");
  };

  return (
    <ModalContainer>
      <ContainerText>
        <Text>
          You are about to permanently delete your account and all data
          associated with it. If you press the "Delete my Account" button, you
          will never be able to recover your account.
        </Text>
      </ContainerText>
      <ContainerText>
        <Text>All your data will be erased, including:</Text>
        <List>
          <ListItem>• Your login information</ListItem>
          <ListItem>• Your email address</ListItem>
          <ListItem>• Your case logs including photos</ListItem>
          <ListItem>
            • Your customized data fields (if you created any)
          </ListItem>
        </List>
      </ContainerText>
      {/* <ContainerText>
        <Text>
          Do you mean instead to UNSUBSCRIBE from Apple/Google billing.
        </Text>
        <Text>Here's how you can unsubscribe from all future payments:</Text>
        <List>
          <ListItem>
            <LinkButton
              href="https://support.apple.com/en-us/118428"
              target="_blank"
            >
              on iOS
            </LinkButton>
          </ListItem>
          <ListItem>
            <LinkButton
              href="https://support.apple.com/en-us/118428"
              target="_blank"
            >
              on Android
            </LinkButton>
          </ListItem>
        </List>
        <ContainerText>
          <Text>
            If you have any questions, comments, or suggestions, feel free to
            email us at{" "}
            <LinkButton href="mailto:info@snaplog.com.au">
              info@snaplog.com.au
            </LinkButton>.
          </Text>
        </ContainerText>
      </ContainerText> */}
      <div style={{ opacity: !confirmDeleting ? 0.38 : 1 }}>
        <ConfirmSection onClick={handleConfirmClick}>
          <RoundButton>
            <MdDeleteOutline size={24} color={baseTheme.colors.error} />
          </RoundButton>
          <Text
            style={{
              textDecoration: confirmDeleting ? "none" : "underline",
              color: baseTheme.colors.black,
              paddingLeft: "15px",
            }}
          >
            Click to proceed to Delete my Account
          </Text>
        </ConfirmSection>
      </div>

      <WrapperButton>
        <Button
          disabled={!confirmDeleting || loading}
          bg={baseTheme.colors.error}
          onClick={handleDelete}
          text={"Delete my Account"}
        />
      </WrapperButton>
    </ModalContainer>
  );
};

export default function DeleteUserAccount({
  isOpen,
  onRequestClose,
}: IDeleteUserAccount) {
  const [confirmDeleting, setConfirmDeleting] = useState(false);
  return (
    <Modal
      icon={<Warn color={baseTheme.colors.error} />}
      isOpen={isOpen}
      onRequestClose={() => {
        setConfirmDeleting(false);
        onRequestClose();
      }}
      children={
        <DeletePersonComponent
          confirmDeleting={confirmDeleting}
          setConfirmDeleting={setConfirmDeleting}
        />
      }
      title={"Delete Account"}
    ></Modal>
  );
}
