import { IIcon } from "interfaces";

const Specimens = ({ color = "#199A8E", width = 28, height = 28 }: IIcon) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
      >
        <g id="_x32_4_x2C__Test_Tube_x2C__blood_test_x2C__blood_x2C__blood_sample_x2C__laboratory_x2C_">
          <g>
            <g>
              <path
                d="M157.176,105.412H53.647c-5.198,0-9.412-4.214-9.412-9.412V58.353c0-5.198,4.214-9.412,9.412-9.412h103.529
				c5.198,0,9.412,4.214,9.412,9.412V96C166.588,101.198,162.374,105.412,157.176,105.412z M63.059,86.588h84.706V67.765H63.059
				V86.588z"
                fill={color}
              />
              <path
                d="M307.765,105.412H204.235c-5.198,0-9.412-4.214-9.412-9.412V58.353c0-5.198,4.214-9.412,9.412-9.412h103.529
				c5.198,0,9.412,4.214,9.412,9.412V96C317.176,101.198,312.962,105.412,307.765,105.412z M213.647,86.588h84.706V67.765h-84.706
				V86.588z"
                fill={color}
              />
              <path
                d="M458.353,105.412H354.824c-5.198,0-9.412-4.214-9.412-9.412V58.353c0-5.198,4.214-9.412,9.412-9.412h103.529
				c5.198,0,9.412,4.214,9.412,9.412V96C467.765,101.198,463.551,105.412,458.353,105.412z M364.235,86.588h84.706V67.765h-84.706
				V86.588z"
                fill={color}
              />
              <path
                d="M105.412,368.941c-23.354,0-42.353-18.999-42.353-42.353V133.647c0-5.198,4.214-9.412,9.412-9.412h65.882
				c5.198,0,9.412,4.214,9.412,9.412v192.941C147.765,349.942,128.766,368.941,105.412,368.941z M81.882,143.059v183.529
				c0,12.974,10.555,23.529,23.529,23.529s23.529-10.555,23.529-23.529V143.059H81.882z"
                fill={color}
              />
              <path
                d="M105.412,368.941c-23.354,0-42.353-18.999-42.353-42.353V96c0-5.198,4.214-9.412,9.412-9.412h65.882
				c5.198,0,9.412,4.214,9.412,9.412v230.588C147.765,349.942,128.766,368.941,105.412,368.941z M81.882,105.412v221.176
				c0,12.974,10.555,23.529,23.529,23.529s23.529-10.555,23.529-23.529V105.412H81.882z"
                fill={color}
              />
              <path
                d="M256,368.941c-23.354,0-42.353-18.999-42.353-42.353V133.647c0-5.198,4.214-9.412,9.412-9.412h65.882
				c5.198,0,9.412,4.214,9.412,9.412v192.941C298.353,349.942,279.354,368.941,256,368.941z M232.471,143.059v183.529
				c0,12.974,10.555,23.529,23.529,23.529s23.529-10.555,23.529-23.529V143.059H232.471z"
                fill={color}
              />
              <path
                d="M256,368.941c-23.354,0-42.353-18.999-42.353-42.353V96c0-5.198,4.214-9.412,9.412-9.412h65.882
				c5.198,0,9.412,4.214,9.412,9.412v230.588C298.353,349.942,279.354,368.941,256,368.941z M232.471,105.412v221.176
				c0,12.974,10.555,23.529,23.529,23.529s23.529-10.555,23.529-23.529V105.412H232.471z"
                fill={color}
              />
              <path
                d="M406.588,368.941c-23.354,0-42.353-18.999-42.353-42.353V133.647c0-5.198,4.214-9.412,9.412-9.412h65.882
				c5.198,0,9.412,4.214,9.412,9.412v192.941C448.941,349.942,429.942,368.941,406.588,368.941z M383.059,143.059v183.529
				c0,12.974,10.555,23.529,23.529,23.529s23.529-10.555,23.529-23.529V143.059H383.059z"
                fill={color}
              />
              <path
                d="M406.588,368.941c-23.354,0-42.353-18.999-42.353-42.353V96c0-5.198,4.214-9.412,9.412-9.412h65.882
				c5.198,0,9.412,4.214,9.412,9.412v230.588C448.941,349.942,429.942,368.941,406.588,368.941z M383.059,105.412v221.176
				c0,12.974,10.555,23.529,23.529,23.529s23.529-10.555,23.529-23.529V105.412H383.059z"
                fill={color}
              />
              <path
                d="M223.059,180.706h-84.706c-5.198,0-9.412-4.214-9.412-9.412s4.214-9.412,9.412-9.412h84.706
				c5.198,0,9.412,4.214,9.412,9.412S228.256,180.706,223.059,180.706z"
                fill={color}
              />
              <path
                d="M486.588,463.059H25.412c-5.198,0-9.412-4.214-9.412-9.412V171.294c0-5.198,4.214-9.412,9.412-9.412h47.059
				c5.198,0,9.412,4.214,9.412,9.412s-4.214,9.412-9.412,9.412H34.824v263.529h442.353V180.706h-37.647
				c-5.198,0-9.412-4.214-9.412-9.412s4.214-9.412,9.412-9.412h47.059c5.198,0,9.412,4.214,9.412,9.412v282.353
				C496,458.845,491.786,463.059,486.588,463.059z"
                fill={color}
              />
              <path
                d="M373.647,180.706h-84.706c-5.198,0-9.412-4.214-9.412-9.412s4.214-9.412,9.412-9.412h84.706
				c5.198,0,9.412,4.214,9.412,9.412S378.845,180.706,373.647,180.706z"
                fill={color}
              />
              <path
                d="M223.059,218.353h-84.706c-5.198,0-9.412-4.214-9.412-9.412s4.214-9.412,9.412-9.412h84.706
				c5.198,0,9.412,4.214,9.412,9.412S228.256,218.353,223.059,218.353z"
                fill={color}
              />
              <path
                d="M223.059,180.706h-84.706c-5.198,0-9.412-4.214-9.412-9.412s4.214-9.412,9.412-9.412h84.706
				c5.198,0,9.412,4.214,9.412,9.412S228.256,180.706,223.059,180.706z"
                fill={color}
              />
              <path
                d="M486.588,218.353h-47.059c-5.198,0-9.412-4.214-9.412-9.412s4.214-9.412,9.412-9.412h37.647v-18.824h-37.647
				c-5.198,0-9.412-4.214-9.412-9.412s4.214-9.412,9.412-9.412h47.059c5.198,0,9.412,4.214,9.412,9.412v37.647
				C496,214.139,491.786,218.353,486.588,218.353z"
                fill={color}
              />
              <path
                d="M373.647,218.353h-84.706c-5.198,0-9.412-4.214-9.412-9.412s4.214-9.412,9.412-9.412h84.706
				c5.198,0,9.412,4.214,9.412,9.412S378.845,218.353,373.647,218.353z"
                fill={color}
              />
              <path
                d="M373.647,180.706h-84.706c-5.198,0-9.412-4.214-9.412-9.412s4.214-9.412,9.412-9.412h84.706
				c5.198,0,9.412,4.214,9.412,9.412S378.845,180.706,373.647,180.706z"
                fill={color}
              />
              <path
                d="M72.471,218.353H25.412c-5.198,0-9.412-4.214-9.412-9.412v-37.647c0-5.198,4.214-9.412,9.412-9.412h47.059
				c5.198,0,9.412,4.214,9.412,9.412s-4.214,9.412-9.412,9.412H34.824v18.824h37.647c5.198,0,9.412,4.214,9.412,9.412
				S77.668,218.353,72.471,218.353z"
                fill={color}
              />
              <path
                d="M486.588,463.059H25.412c-5.198,0-9.412-4.214-9.412-9.412V416c0-5.198,4.214-9.412,9.412-9.412h461.176
				c5.198,0,9.412,4.214,9.412,9.412v37.647C496,458.845,491.786,463.059,486.588,463.059z M34.824,444.235h442.353v-18.824H34.824
				V444.235z"
                fill={color}
              />
            </g>
          </g>
        </g>
        <g id="Layer_1"></g>
      </svg>
    </div>
  );
};

export default Specimens;
