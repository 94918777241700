import React, { useState } from "react";
import styled from "styled-components";
import { LandingTitleSection } from "./About";
import { baseTheme } from "styles/theme";

interface FAQItem {
  question: string;
  answer: string;
}

interface AccordionProps {
  faqs: FAQItem[];
}

interface StyledProps {
  active?: boolean;
}

const AccordionContainer = styled.div`
  max-width: 700px;
  margin: auto;
  padding: 100px 15px 20px;
  text-align: center;
  @media (min-width: 2500px) {
    max-width: 850px;
  }
`;

const TitleList = styled.ul`
  text-align: left;
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
`;

const TitleListItem = styled.li`
  overflow: hidden;
  padding: 0;
  margin-top: 20px;
  background-color: ${baseTheme.colors.white};
  border-radius: 15px;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
`;

const TitleListItemTitle = styled.h3`
  font-size: 18px;
  color: ${baseTheme.colors.main};
  font-weight: 600;
  padding: 20px 20px 0;
  line-height: 26px;
  text-transform: uppercase;
  text-align: left;
  padding-left: 15px;
  text-transform: none;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  cursor: pointer;
  transition: 0.2s;
  @media (min-width: 1921px) {
    font-size: 21px;
    line-height: 29px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
    line-height: 40px;
  }
  span {
    font-size: 30px;
    line-height: 0;
    font-weight: 400;
    color: ${baseTheme.colors.main};
    transition: transform 0.6s;
    padding-left: 10px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px;
  color: #484848;
  font-weight: 500;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 30px;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 29px;
  }
  span {
    padding-right: 2px;
    color: ${baseTheme.colors.main};
  }
`;

const LinkMail = styled.a`
  padding: 0;
  color: ${baseTheme.colors.link};
  font-weight: 500;
  font-size: 16px;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 29px;
  }
`;

const TitleListItemText = styled.p<StyledProps>`
  letter-spacing: 0;
  color: #484848;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: left;
  overflow: hidden;
  max-height: ${(props) => (props.active ? "1000px" : "0")};
  display: inline-block;
  opacity: ${(props) => (props.active ? "1" : "0")};
  transform: translate(0, ${(props) => (props.active ? "0" : "50%")});
  margin-bottom: 15px;
  margin-top: 10px;
  padding: 0 70px 0 15px;
  transition: 0.8s opacity, 0.8s max-height, 0.8s transform;
  @media (min-width: 1921px) {
    font-size: 19px;
    line-height: 29px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
    line-height: 40px;
  }
`;

const FaqAccordion: React.FC<AccordionProps> = ({ faqs }) => {
  const [activeItems, setActiveItems] = useState<{ [key: number]: boolean }>(
    {}
  );

  const toggleAccordion = (index: number) => {
    setActiveItems((prevState) => {
      const isItemActive = prevState[index];
      return {
        ...prevState,
        [index]: !isItemActive,
      };
    });
  };

  return (
    <AccordionContainer id="faq">
      <LandingTitleSection>FAQ</LandingTitleSection>
      <TitleList>
        {faqs.map((faq, index) => (
          <TitleListItem key={index}>
            <TitleListItemTitle onClick={() => toggleAccordion(index)}>
              {faq.question}
              <span>{activeItems[index] ? "-" : "+"}</span>
            </TitleListItemTitle>
            <TitleListItemText active={!!activeItems[index]}>
              {faq.answer}
            </TitleListItemText>
          </TitleListItem>
        ))}
      </TitleList>
      <Text>
        <span>*</span>
        Please reach us at{" "}
        <LinkMail href="mailto:info@snaplog.com.au">
          info@snaplog.com.au
        </LinkMail>{" "}
        if you cannot find an answer to your question.
      </Text>
    </AccordionContainer>
  );
};

export default FaqAccordion;
