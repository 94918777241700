import styled from "styled-components";
import { baseTheme } from "styles/theme";

export const ReviewCaseWrapper = styled.div`
  padding: 50px 20px 10px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-around;
  flex-direction: row;
  @media (max-width: 1100px) {
    width: 400px;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 500px) {
    width: 300px;
  }
`;

export const SubTitleSection = styled.h4`
  color: ${baseTheme.colors.black};
  text-align: center;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 600;
`;

export const ReviewCaseRow = styled.div`
  display: flex;
  width: 40%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Column = styled.div`
  padding: 5px 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${baseTheme.colors.gray};
  @media (max-width: 500px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export const ColumnEdit = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 500px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
export const Title = styled.p`
  font-size: 15px;
  width: 45%;
  line-height: 20px;
  font-weight: 500;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const StyledText = styled.span`
  word-wrap: break-word;
  font-size: 15px;
  width: 50%;
  padding-left: 15px;
  flex: 1;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  @media (max-width: 500px) {
    width: 120px;
  }
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const TitleOutcome = styled.div`
  font-size: 1.4rem;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  color: ${baseTheme.colors.textTable};
  @media (max-width: 500px) {
    font-size: 1.1rem;
  }
  @media (min-width: 1921px) {
    font-size: 32px;
  }
`;

export const Outcomes = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 450px;
  flex-direction: column;
`;

export const OutcomesPathology = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 300px;
  padding-top: 20px;
  align-self: center;
`;

export const SkeletonWrapper = styled.div`
  padding: 5px 0;
  border: 1px solid ${baseTheme.colors.gray};
`;

export const TitleSection = styled.div`
  color: ${baseTheme.colors.black};
  align-items: center;
  width: 100%;
  padding: 0px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const ButtonEdit = styled.span`
  cursor: pointer;
`;
export const TitleSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const InfoLogWrap = styled.div`
  padding-bottom: 10px;
  height: 100%;
`;
export const DFlex = styled.div`
  flex: 1;
  padding: 20px 0;
`;