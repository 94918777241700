import React from "react";
import styled from "styled-components";
import { StyledH4 } from "styles/components";

const ReportsWrap = styled.div`
  padding: 20px;
  min-height: 95vh;
`;

const Reports = () => {
  return (
    <ReportsWrap>
      <StyledH4>Coming soon...</StyledH4>
    </ReportsWrap>
  );
};

export default Reports;
