import styled from "styled-components";
import { useEffect, useState } from "react";
import { baseTheme } from "styles/theme";
import { getToken, getUserRole } from "helpers";
import User from "assets/icons/User";
import Logo from "assets/icons/Logo";
import { Link } from "react-router-dom";

interface NavigationProps {
  isHovered?: boolean;
  isScrolled?: boolean;
}

const HeaderStyledDesc = styled.header<NavigationProps>`
  position: fixed;
  width: 100%;
  padding: 15px;
  z-index: 5;
  background-color: ${(props) => (props.isScrolled ? "white" : "transparent")};
  transition: opacity 0.3s ease;
  @media (max-width: 991px) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
  @media (min-width: 1921px) {
    max-width: 1140px;
  }
  @media (min-width: 2500px) {
    max-width: 1440px;
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NavigationWrap = styled.div`
  display: flex;
  align-items: center;
`;

const LinkStyled = styled.a<NavigationProps>`
  padding: 0 20px;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) =>
    props.isScrolled ? baseTheme.colors.black : baseTheme.colors.white};
  font-weight: 600;
  list-style: none;
  transition: color 0.5s, opacity 0.5s;
  &:hover {
    color: ${(props) =>
      props.isScrolled ? baseTheme.colors.black : baseTheme.colors.white};
    opacity: 0.7;
  }
  @media (min-width: 1921px) {
    font-size: 19px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
  }
`;
export const MenuItem = styled(Link)`
  display: flex;
  font-family: "Poppins", sans-serif;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  color: ${baseTheme.colors.black};
  cursor: pointer;
  padding: 15px 10px;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  position: relative;
  transition: color 0.5s, opacity 0.5s, background-color 0.5s;

  &:hover {
    color: ${baseTheme.colors.white};
    /* background-color: ${baseTheme.colors.main}; */
    border: 1px solid linear-gradient(to bottom right, #199a8e, black);
    background-image: linear-gradient(to bottom right, #199a8e, black);
  }

  &:first-child {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &:only-child {
    border-radius: 20px;
  }
  @media (min-width: 1921px) {
    font-size: 19px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
  }
`;

const Navigation = styled.div<NavigationProps>`
  position: absolute;
  z-index: 5;
  cursor: pointer;
  top: 100%;
  right: 0;
  width: 200px;
  background-color: white;
  border-radius: 20px;
  display: ${(props) => (props.isHovered ? "block" : "none")};
`;

function HeaderMenuDesktop() {
  const token = getToken();
  const statusRole = getUserRole();
  const [isHovered, setIsHovered] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [colorFlag, setColorFlag] = useState<"white" | "black" | "main">(
    "white"
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
      const flag = scrollTop > 0 ? "black" : "white";
      setColorFlag(flag);
    };

    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsLargeScreen(screenWidth > 2500);
    };

    handleResize();

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleIconHover = () => {
    setIsHovered(!isHovered);
  };

  const linkHome =
    statusRole === "admin" || statusRole === "superadmin"
      ? "/users"
      : "/case-logs";

  return (
    <HeaderStyledDesc isScrolled={isScrolled}>
      <Container>
        <TitleWrap>
          <Logo flag={colorFlag} />
        </TitleWrap>
        <NavigationWrap>
          <LinkStyled isScrolled={isScrolled} href="#about">
            About
          </LinkStyled>
          <LinkStyled isScrolled={isScrolled} href="#pricing">
            Pricing
          </LinkStyled>
          <LinkStyled isScrolled={isScrolled} href="#faq">
            FAQ
          </LinkStyled>
          <div
            style={{
              position: "relative",
              cursor: "pointer",
              paddingLeft: "20px",
            }}
            onMouseEnter={handleIconHover}
            onMouseLeave={handleIconHover}
          >
            <User
              width={isLargeScreen ? 29 : 19}
              height={isLargeScreen ? 29 : 19}
              strokeWidth={isLargeScreen ? 3.5 : 2.5}
              color={
                isScrolled ? baseTheme.colors.black : baseTheme.colors.white
              }
            />
            <Navigation isHovered={isHovered}>
              {token && <MenuItem to={linkHome}>My Account</MenuItem>}

              {!token && (
                <>
                  <MenuItem to="/login">Login</MenuItem>
                  <MenuItem to="/register">Sign up</MenuItem>
                </>
              )}
            </Navigation>
          </div>
        </NavigationWrap>
      </Container>
    </HeaderStyledDesc>
  );
}

export default HeaderMenuDesktop;
