import { IIcon } from "interfaces";

const DeleteUserAccount = ({ color, width = 20, height = 20 }: IIcon) => {
  return (
    <div style={{ width: width, height: height, paddingRight: "5px" }}>
      <svg

        width={width}
        stroke={color}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
    
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"

      >
        <path
        stroke={color}
        d="M2 21a8 8 0 0 1 11.873-7" />
        <circle cx="10" cy="8" r="5" />
        <path d="m17 17 5 5" />
        <path d="m22 17-5 5" />
      </svg>
    </div>
  );
};

export default DeleteUserAccount;
