/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { IUserLStorage, getFromLocalStorageUser, getToken } from "helpers";
import { useAxios } from "hooks/useAxios";
import { useEffect, useState } from "react";
import Toast from "components/Toast";
import {
  ICaseLog,
  IDataSettingsProfile,
  IOutcome,
  ISettings,
} from "interfaces";
import Button from "components/Buttons/Button";
import Modal from "components/Modal/Modal";
import InfoLog from "components/InfoLog/InfoLog";
import {
  ButtonWrapper,
  Outcomes,
  ReviewCaseWrapper,
  Title,
  TitleOutcome,
} from "components/InfoLog/styles";
import { baseTheme } from "styles/theme";
import OutcomeModal from "components/Modal/Outcomes";
import { useNavigate, useLocation } from "react-router-dom";
import useReviewCaseImagesStore from "store/reviewCaseImages";
import styled from "styled-components";
import { ButtonSubscribe } from "pages/Profile/components/ProfileUserinfo";
import FileInput from "./FileInputs";
import Spinner from "./Spinners/ButtonSpinner";
import Specimens from "assets/icons/Specimens";
import PathIcon from "assets/icons/Pathology";
import OutcomeIcon from "assets/icons/Complications";
import PathologySamplesLog from "./PathologySamplesLog";

const ReviewCaseRowInfo = styled.div`
  width: 450px;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (min-width: 2500px) {
    width: 650px;
  }
`;
const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 100px;
`;
const ButtonSubscribeWrap = styled.div`
  padding: 20px 0;
  margin: 0 auto;
`;
const TitleSection = styled.h4`
  color: ${baseTheme.colors.black};
  text-align: center;
  padding: 20px;
  font-size: 16px;
  line-height: 1.7;
  font-weight: 600;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 30px;
  }
`;

export interface IColumnOutcome {
  direction: boolean;
}

export const ColumnOutcome = styled.div<IColumnOutcome>`
  padding: 5px 0;
  display: flex;
  width: 100%;
  width: 450px;
  flex-direction: ${(props) => (props.direction ? "row" : "column")};
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${baseTheme.colors.gray};
  @media (max-width: 747px) {
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
  }
  @media (min-width: 2500px) {
    width: 650px;
  }
`;
const StyledText = styled.span`
  word-wrap: break-word;
  font-size: 15px;
  width: 45%;
  padding: 5px 0;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  @media (max-width: 500px) {
    width: 120px;
  }
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 30px;
  }
`;
const ReviewCaseRowOutcomes = styled.div`
  display: flex;
  max-width: 450px;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const modalIcon = (type: string): JSX.Element | null => {
  switch (type) {
    case "pathology_outcome":
      return <PathIcon color={baseTheme.colors.main} width={36} height={36} />;
    case "complication_outcome":
      return (
        <OutcomeIcon color={baseTheme.colors.main} width={36} height={36} />
      );
    case "pathology_sections":
      return <Specimens width={36} height={36} />;
    default:
      return null;
  }
};

function ReviewCase() {
  const { setImages } = useReviewCaseImagesStore();
  const [imageLength, setImagesLength] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpenPathModal, setIsOpenPathModal] = useState(
    location.state?.isOpenPathModal
  );

  const id = localStorage.getItem("reviewId");
  const [titleOutcomeModal, setTitleOutcomeModal] = useState<string>("");
  const [outcomes, setOutcomes] = useState<IOutcome[]>([]);
  const [logLoading, setLogLoading] = useState(false);
  const [currentOutcomes, setCurrentOutcomes] = useState<IOutcome[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const token = getToken();

  const [queryFieldName, setQueryFieldName] = useState<string>("");
  const [log, setLog] = useState<ICaseLog>();
  const [operationCaseFields, setOperationCaseFields] = useState<ISettings>();
  const { request, error, loading } = useAxios();
  const [specimens, setSpecimens] = useState([]);
  const [caseSpecimens, setCaseSpecimens] = useState([]);
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { role } = user || {};
  const [showPreview, setShowPreview] = useState(false);
  const [previews, setPreviews] = useState<File[]>([]);
  const [previewsModal, setPreviewsModal] = useState<string[]>([]);
  const [settingData, setSettingData] = useState<IDataSettingsProfile>();

  useEffect(() => {
    getSettings();
    getLog();
  }, []);

  useEffect(() => {
    if (isOpenPathModal) {
      getPathologyOutcome();
    }
  }, []);

  const getSettings = async () => {
    const settings = await request(
      "profile/operation-case/settings",
      "GET",
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setSettingData(settings?.data[0]);
    setOperationCaseFields(settings?.data[0]["operation_case_fields"]);
  };

  const getPathologyOutcome = async () => {
    setOutcomes(settingData!["pathology-outcomes"]);
    setTitleOutcomeModal("Add pathology outcome");
    setQueryFieldName("pathology_outcome");
    setCurrentOutcomes(log?.pathology_outcome || []);
    setIsModalOpen(true);
    // togglePathOutcome();
  };

  const getComplicationOutcome = async () => {
    setCurrentOutcomes(log!.complication_outcome || []);
    setOutcomes(settingData!["complication-outcomes"]);
    setTitleOutcomeModal("Add complication outcome");
    setQueryFieldName("complication_outcome");
    setIsModalOpen(true);
  };

  const getSpecimens = async () => {
    setOutcomes(settingData!["pathology-outcomes"]);
    setSpecimens(settingData!.pathology_samples);
    setTitleOutcomeModal("Add pathology specimens");
    setQueryFieldName("pathology_sections");
    setIsModalOpen(true);
  };

  const operationCaseRoute =
    role === "user"
      ? `operation-case/${id}`
      : `admin/user/2/operation-case/${id}`;
  const closeModal = () => {
    setIsOpenPathModal(false);
    setIsModalOpen(false);
  };

  const opNote = () => {
    navigate("/notes");
  };

  async function getLog() {
    try {
      setLogLoading(true);
      const res = await request(operationCaseRoute, "GET", null, {
        Authorization: ` Bearer ${token}`,
      });
      setCaseSpecimens(res.data.pathology_sections);
      setLog(res?.data);
      setImages({ images: res?.data.images });
      setImagesLength(res?.data.images.length);
    } catch (e) {
    } finally {
      setLogLoading(false);
    }
  }

  async function getUpdateLog() {
    getLog();
    closeModal();
  }
  const checkPathSpecimen = async (
    idSpecimen: string,
    idOutcome: string,
    value: boolean
  ) => {
    const res = await request(
      `pathology-section/${idSpecimen}`,
      "POST",
      {
        pathology_outcome_id: idOutcome,
        checked: !value,
      },
      {
        Authorization: ` Bearer ${token}`,
      }
    );
    if (res) {
      await getLog();
    }
  };

  async function addPhotos() {
    const formData = new FormData();
    previews.forEach((image, index) => {
      const fieldName = `files[${index}]`;
      formData.append(fieldName, image);
    });
    try {
      const res = await request(
        `operation-case/${id}/photo`,
        "POST",
        formData,
        {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        }
      );
      setShowPreview(false);
      if (res) {
        getLog();
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }
  const handleFileInputChange = (selectedFiles: File[] | null) => {
    if (selectedFiles !== null) {
      setPreviews(selectedFiles);
    }
  };

  return (
    <>
      <TitleOutcome>Review Case</TitleOutcome>
      {logLoading ? (
        <SpinnerWrapper>
          <Spinner size={60} />
        </SpinnerWrapper>
      ) : (
        <ReviewCaseWrapper>
          <ReviewCaseRowInfo>
            <InfoLog
              operationCaseFields={operationCaseFields!}
              log={log!}
              logLoading={logLoading}
              getLog={getLog}
            />
            <ButtonSubscribeWrap>
              {imageLength ? (
                <ButtonSubscribe onClick={opNote}>
                  View Operation Note
                </ButtonSubscribe>
              ) : (
                role === "user" && (
                  <FileInput
                    loading={loading}
                    previewsModal={previewsModal}
                    setPreviewsModal={setPreviewsModal}
                    showPreview={showPreview}
                    setShowPreview={setShowPreview}
                    addPhotos={addPhotos}
                    title="Add Operation Note"
                    onChange={handleFileInputChange}
                    isIcon={false}
                    link
                  />
                )
              )}
            </ButtonSubscribeWrap>
          </ReviewCaseRowInfo>
          <ReviewCaseRowOutcomes>
            <TitleSection>Outcomes</TitleSection>
            <Outcomes>
              <ColumnOutcome direction={true}>
                <Title>Pathology:</Title>
                <div style={{ width: "65%" }}>
                  {log &&
                  log.pathology_outcome &&
                  log.pathology_outcome.length ? (
                    log.pathology_outcome.map((item: IOutcome) => (
                      <div key={item.id}>
                        <StyledText>{item.name}</StyledText>
                      </div>
                    ))
                  ) : (
                    <StyledText>N/A</StyledText>
                  )}
                </div>
              </ColumnOutcome>

              <ColumnOutcome direction={true}>
                <Title>Complication:</Title>
                <div style={{ width: "65%" }}>
                  {log &&
                  log.complication_outcome &&
                  log.complication_outcome.length ? (
                    log.complication_outcome.map((item: IOutcome) => (
                      <div key={item.id}>
                        <StyledText>{item.name}</StyledText>
                      </div>
                    ))
                  ) : (
                    <>N/A</>
                  )}
                </div>
              </ColumnOutcome>
            </Outcomes>
            {settingData && (
              <PathologySamplesLog
                getLog={getLog}
                log={log!}
                settingData={settingData}
                caseSpecimens={caseSpecimens}
                checkPathSpecimen={checkPathSpecimen}
              />
            )}

            {role === "user" && (
              <>
                <ButtonWrapper>
                  <Button
                    onClick={getPathologyOutcome}
                    text="Add pathology outcome"
                  />
                </ButtonWrapper>
                <ButtonWrapper>
                  <Button
                    onClick={getComplicationOutcome}
                    text="Add complication outcome"
                  />
                </ButtonWrapper>

                <ButtonWrapper>
                  <Button
                    onClick={getSpecimens}
                    text="Add pathology specimens"
                  />
                </ButtonWrapper>
              </>
            )}
          </ReviewCaseRowOutcomes>
        </ReviewCaseWrapper>
      )}
      {error && <Toast error={error} />}
      <Modal
        title={titleOutcomeModal}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        icon={modalIcon(queryFieldName)}
      >
        <OutcomeModal
          title={titleOutcomeModal}
          id={id!}
          log={log}
          specimens={specimens}
          options={outcomes}
          queryFieldName={queryFieldName}
          currentOutcomes={currentOutcomes}
          getUpdateLog={getUpdateLog}
        />
      </Modal>
    </>
  );
}

export default ReviewCase;
