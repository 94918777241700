import styled from "styled-components";
import { baseTheme } from "styles/theme";
import { useState, ChangeEvent, FocusEvent } from "react";

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input<{ isOpDetails?: boolean }>`
  border: ${(props) =>
    props.isOpDetails ? "none" : `1px solid ${baseTheme.colors.gray}`};
  outline: none;
  font-family: "Poppins", sans-serif;
  padding: 12px;
  height: ${(props) => (props.isOpDetails ? "100%" : "50px")};
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: ${(props) => (props.isOpDetails ? "0" : "49px")};
  background-color: ${(props) =>
    props.isOpDetails ? "transparent" : baseTheme.colors.gray};
  width: 100%;
  @media (min-width: 1921px) {
    font-size: 18px;
  }
  &::placeholder {
    color: #b4b4b4 !important;
  }
`;

const Suggestions = styled.div<{ isOpDetails?: boolean }>`
  position: absolute;
  border:  1px solid ${baseTheme.colors.main};
  width: 100%;
  border-radius: ${(props) => (props.isOpDetails ? 0 : "8px")};
  max-height: 150px;
  overflow-y: auto;
  z-index: 99;
`;

const SuggestionItem = styled.div<{ isOpDetails?: boolean }>`
  padding: 8px;
  z-index: 4;
  background: ${baseTheme.colors.gray};
  transition: background-color 0.3s;
  cursor: pointer;
  z-index: 11;
  &:hover {
    background: ${baseTheme.colors.main};
    color: ${baseTheme.colors.gray};
  }
`;
const StyledErrorWrap = styled.div`
  position: absolute;
  color: ${baseTheme.colors.error};
  font-size: 0.7rem;
  bottom: -30%;
  padding-left: 5px;
`;
const AddButton = styled.button`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  padding: 4px 8px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  background-color: "transparent";
  color: ${baseTheme.colors.black};
  border: none;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
  }
`;
interface AutoCompleteInputProps {
  value: string;
  allSupervisors: any;
  onChange: (value: string) => void;
  isOpDetails?: boolean;
  placeholder?: string;
  isAddButton?: boolean;
  selectedSpecimens?: {
    outcomeId?: number;
    specimenId?: string;
    specimenName?: string;
  }[];
  onAddClick?: () => void;
}

export interface Supervisor {
  id: number;
  name: string;
  last_used: string;
  placeholder?: string;
}

const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
  value,
  allSupervisors,
  onChange,
  placeholder,
  onAddClick,
  selectedSpecimens,
  isAddButton = false,
  isOpDetails = true,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [error, setError] = useState("");
  const handleSuggestionClick = (suggestion: string) => {
    onChange(suggestion);
    setShowSuggestions(false);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setTimeout(() => setShowSuggestions(false), 100);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    const isDuplicate = selectedSpecimens?.some(
      (specimen) => specimen.specimenName === newValue
    );

    if (isDuplicate) {
      onChange(event.target.value);
      setError("Specimen must be unique");
    } else {
      onChange(event.target.value);
      setError("");
    }
    setShowSuggestions(true);
  };

  const filteredOptions = allSupervisors?.filter((option: Supervisor) =>
    String(option?.name).toLowerCase().includes(String(value).toLowerCase())
  );

  return (
    <Wrapper>
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={() => setShowSuggestions(true)}
        isOpDetails={isOpDetails}
      />
      {error && <StyledErrorWrap>{error}</StyledErrorWrap>}
      {isAddButton && <AddButton onClick={onAddClick}>Add new</AddButton>}
      {showSuggestions && (
        <Suggestions>
          {filteredOptions.map((option: Supervisor) => (
            <SuggestionItem
              key={option.id}
              onMouseDown={() => handleSuggestionClick(option.name)}
            >
              {option.name}
            </SuggestionItem>
          ))}
        </Suggestions>
      )}
    </Wrapper>
  );
};

export default AutoCompleteInput;
