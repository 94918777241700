import { getFromLocalStorageUser, IUserLStorage } from "helpers";
import Modal from "components/Modal/Modal";
import AndroidTextSub from "./AndroidTextSub";
import IOSTextSub from "./IOSTextSub";
import WebTextSub from "./WebTextSub";
import { SubContainer } from "./subManagerStyles";
import { SubButton, Text } from "./subManagerStyles";
import Button from "components/Buttons/Button";
import { baseTheme } from "styles/theme";
import Subscription from "assets/icons/Subscription";
interface IModalContainer {
  confirmHandlePayment: () => void;
}

interface ISubscriptionManager {
  isOpen: boolean;
  onRequestClose: () => void;
  confirmHandlePayment: () => void;
}

function ModalContainer({ confirmHandlePayment }: IModalContainer) {
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { subscription_provider, subscribe_status } = user;
  let titleSubscribe = "";
  switch (subscribe_status) {
    case "demo":
      titleSubscribe = "Would you like to subscribe?";
      break;
    case "demo-expired":
      titleSubscribe =
        "Your free trial has expired. Would you like to subscribe?";
      break;
    case "subscribe-expired":
      titleSubscribe =
        "Your subscription has expired. Would you like to subscribe?";
      break;
  }
  return (
    <SubContainer>
      {subscription_provider === null && (
        <>
          <Text>{titleSubscribe}</Text>
          <SubButton>
            <Button
              fontWeight={500}
              fontSize="14px"
              onClick={confirmHandlePayment}
              bg={baseTheme.colors.main}
              text={"Confirm"}
            />
          </SubButton>
        </>
      )}
      {subscription_provider === "stripe" && (
        <WebTextSub confirmHandlePayment={confirmHandlePayment} />
      )}
      {subscription_provider === "apple" && <IOSTextSub />}
      {subscription_provider === "google" && <AndroidTextSub />}
    </SubContainer>
  );
}

export default function SubscriptionManager({
  isOpen,
  onRequestClose,
  confirmHandlePayment,
}: ISubscriptionManager) {
  return (
    <Modal
      icon={<Subscription color={baseTheme.colors.main}/>}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      children={<ModalContainer confirmHandlePayment={confirmHandlePayment} />}
      title={"Subscription information"}
    ></Modal>
  );
}
