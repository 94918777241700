import { useAxios } from "hooks/useAxios";
import { useMemo, useState } from "react";
import Toast from "components/Toast";
import CustomOptionField, { ButtonWrapper } from "./CustomOptionField";
import Input from "components/Inputs/Input";
import Button from "components/Buttons/Button";
import { baseTheme } from "styles/theme";
import { getToken } from "helpers";
import { IData } from "interfaces";
import { FormCustomField, SpinnerWrapper } from "./style";
import { TitleSection } from "styles/components";
import Spinner from "components/Spinners/ButtonSpinner";
import useCustomFieldStore from "store/customFieldId";

interface ISetOptionField {
  getCustomFields: () => void;
  title: string;
  closeModal: (value: boolean) => void;
}

function SetOptionField({
  getCustomFields,
  closeModal,
  title,
}: ISetOptionField) {
  const [text, setText] = useState("");
  const [option] = useState("");
  const token = getToken();
  const [loader, setLoader] = useState(false);
  const { request, loading, error } = useAxios();
  const { setCustomFieldId } = useCustomFieldStore();
  const [data, setData] = useState<IData | null>(null);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .slice(0, 40);
    setText(inputValue);
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoader(true);
    const res = await request(
      "profile/custom-field",
      "POST",
      {
        name: text,
        type: "options",
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    setCustomFieldId(res.data.id)
    setData(res.data);
    getCustomFields();
    setLoader(false);
  }

  const buttonDisabled = useMemo(() => {
    return !text;
  }, [text]);

  const buttonAddOptionsDisabled = useMemo(() => {
    return !(data !== null);
  }, [data]);

  return (
    <>
      {!data && (
        <>
          <TitleSection>{title}</TitleSection>

          {loader ? (
            <SpinnerWrapper>
              <Spinner color={baseTheme.colors.main} />
            </SpinnerWrapper>
          ) : (
            <>
              <TitleSection>{`Selector custom field name`}</TitleSection>
              <FormCustomField onSubmit={handleSubmit}>
                <Input
                  type="text"
                  name="text"
                  required={true}
                  placeholder={"Enter Name"}
                  value={text}
                  onChange={handleInputChange}
                />
                <ButtonWrapper>
                  <Button
                    fontWeight={600}
                    color={baseTheme.colors.white}
                    fontSize="14px"
                    disabled={buttonDisabled || loading}
                    text="Save"
                  />
                </ButtonWrapper>
              </FormCustomField>
              <TitleSection>{`Selector custom field options`}</TitleSection>
              <FormCustomField onSubmit={handleSubmit}>
                <Input
                  type="text"
                  name="text"
                  required={true}
                  placeholder={"Add new custom field option"}
                  value={option}
                  onChange={handleInputChange}
                />
                <ButtonWrapper>
                  <Button
                    fontWeight={600}
                    color={baseTheme.colors.white}
                    fontSize="14px"
                    disabled={buttonAddOptionsDisabled || loading}
                    text="Save"
                  />
                </ButtonWrapper>
              </FormCustomField>
            </>
          )}
        </>
      )}
      {data !== null && (
        <CustomOptionField
          data={data}
          getCustomFields={getCustomFields}
          setData={setData}
          closeModal={closeModal}
        />
      )}
      {error && <Toast error={error} />}
    </>
  );
}

export default SetOptionField;
