import { getToken } from "helpers";
import React, { useState } from "react";
import styled from "styled-components";
import { CurrentPageData } from "./UserTable";
import DoubleLeftOutlined from "@ant-design/icons/DoubleLeftOutlined";
import DoubleRightOutlined from "@ant-design/icons/DoubleRightOutlined";
interface PaginationProps {
  currentPage: number;
  totalPages: number;
  setUsersData: (value: []) => void;
  setMeta: (value: CurrentPageData) => void;
  links: { url: string; label: string; active: boolean }[];
}

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button<{
  active?: boolean;
  disabled?: boolean;
  loading?: boolean;
}>`
  border: none;
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  background-color: ${(props) => (props.active ? "#000" : "#fff")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  cursor: ${(props) =>
    props.disabled || props.loading ? "not-allowed" : "pointer"};
  padding: 5px 10px;
  margin: 0 2px;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
  @media (min-width: 1280px) {
    font-size: 14px;
  }
  @media (min-width: 1921px) {
    font-size: 18px;
    padding: 10px 15px;
  }
  &:hover {
    background-color: ${(props) =>
      !props.disabled && !props.loading && !props.active
        ? "#000"
        : props.active
        ? "#000"
        : "#fff"};
    color: ${(props) =>
      !props.disabled && !props.loading && !props.active
        ? "#fff"
        : props.active
        ? "#fff"
        : "#000"};
  }
`;

const Pagination: React.FC<PaginationProps> = ({
  setMeta,
  setUsersData,
  links,
}) => {
  const token = getToken();
  const [loading, setLoading] = useState(false);
  const handlePageChange = async (url: string) => {
    setLoading(true);
    try {
      const response = await fetch(`${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const newData = await response.json();
      setMeta(newData.meta);
      setUsersData(newData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const filteredLinks = links.filter(
    (link) => link.label !== "&laquo; Previous" && link.label !== "Next &raquo;"
  );
  return (
    <PaginationContainer>
      <PageButton
        onClick={() => {
          const prevLink = links.find(
            (link) => link.label === "&laquo; Previous"
          );
          if (prevLink) {
            handlePageChange(prevLink.url);
          }
        }}
        disabled={!links.some((link) => link.label === "&laquo; Previous")}
        loading={loading}
      >
        <DoubleLeftOutlined />
      </PageButton>
      {filteredLinks.map((link) => (
        <PageButton
          key={link.label}
          active={link.active}
          onClick={() => handlePageChange(link.url)}
          disabled={
            link.label === "&laquo; Previous" || link.label === "Next &raquo;"
          }
          loading={loading}
        >
          {link.label}
        </PageButton>
      ))}
      <PageButton
        onClick={() => {
          const nextLink = links.find((link) => link.label === "Next &raquo;");
          if (nextLink) {
            handlePageChange(nextLink.url);
          }
        }}
        disabled={!links.some((link) => link.label === "Next &raquo;")}
        loading={loading}
      >
        <DoubleRightOutlined />
      </PageButton>
    </PaginationContainer>
  );
};

export default Pagination;
