import { TitleSection } from "styles/components";
import { StyledText } from "./InfoLog/styles";
import ToggleSwitch from "./ToggleSwitcher";
import { styled } from "styled-components";
import { baseTheme } from "styles/theme";
import { ColumnOutcome } from "./ReviewCase";
import { DataItem, ICaseLog, IDataSettingsProfile } from "interfaces";
import { useEffect, useState } from "react";
import EditIcon from "assets/icons/EditIcon";
import AutoCompleteInput from "./AutoCompleteInput";
import Select from "./Select";
import { CloseButton, CloseIcon } from "./Modal/UpdateProfileModal";
import CheckIcon from "assets/icons/CheckIcon";
import Cancel from "assets/icons/Cancel";
import { useAxios } from "hooks/useAxios";
import { getToken } from "helpers";

import { SpinnerWrapper } from "pages/Profile/components/CustomFields/style";
import Spinner from "./Spinners/ButtonSpinner";

interface IPathologySamplesLog {
  caseSpecimens: DataItem[];
  getLog: () => void;
  log:ICaseLog;
  settingData: IDataSettingsProfile;
  checkPathSpecimen: (
    idSpecimen: string,
    idOutcome: string,
    value: boolean
  ) => void;
}
interface Specimen {
  id: string;
  name: string;
}
const SwitchCell = styled.div`
  display: flex;
  align-items: flex-end;
  align-items: center;
`;

const TableRow = styled.div`
  display: flex;
  padding: 5px 0;
  width: 450px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${baseTheme.colors.gray};
  @media (max-width: 500px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  &:last-child {
    border-bottom: none;
  }
`;
export const Separator = styled.div`
  width: 1px;
  height: 18px;
  background: #ccc;
  margin: 0 4px;
`;
const EditButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export default function PathologySamplesLog({
  caseSpecimens,
  checkPathSpecimen,
  settingData,
  getLog,
  log
}: IPathologySamplesLog) {
  const [edit, setEdit] = useState<DataItem | null>(null);
  const token = getToken();
  const [specimens] = useState(settingData!.pathology_samples);
  const [outcome] = useState(settingData!["pathology-outcomes"]);
  const { request, loading } = useAxios();
  const [uncheckedSpecimens, setUncheckedSpecimens] = useState<
    DataItem[] | null
  >(null);
  useEffect(() => {
    const filteredSpecimens = caseSpecimens.filter(
      (specimen) => !specimen.checked
    );
    setUncheckedSpecimens(filteredSpecimens);
  }, [caseSpecimens]);
console.log('log',log)
  const handleSpecimenNameChange = (value: string) => {
    if (edit) {
      const foundSpecimen = specimens.find(
        (specimen: Specimen) => specimen?.name === value
      );

      if (foundSpecimen) {
        setEdit({
          ...edit,
          pathology_sample: foundSpecimen,
        });
      } else {
        const newSpecimen = { name: value, id: "" };
        setEdit({
          ...edit,
          pathology_sample: newSpecimen,
        });
      }
    }
  };
  const checkAllSpecimens = async () => {
    if (uncheckedSpecimens !== null) {
      console.log("caseSpecimens", caseSpecimens);
  

      const updatedSpecimens = caseSpecimens.map((specimen) => ({
        ...specimen,
        checked: 1,
      }));
  
      const formData = new FormData();
  
      updatedSpecimens.forEach((specimen: any, index) => {
        formData.append(`pathology_sections[${index}][id]`, specimen.id);
        formData.append(`pathology_sections[${index}][checked]`, specimen.checked); 
        formData.append(`pathology_sections[${index}][pathology_outcome_id]`, specimen.pathology_outcome.id);
        formData.append(`pathology_sections[${index}][pathology_sample_id]`, specimen.pathology_sample.id);
      });
        await request(
        `operation-case/${log.id}`,
        "POST",
        formData,
        {
          Authorization: `Bearer ${token}`,
        }
      );
  

      getLog();
    }
  };
  
  const handleOutcomeChange = (value: number) => {
    if (edit) {
      setEdit({
        ...edit,
        pathology_outcome: { id: value, name: "", last_used: "" },
      });
    }
  };

  const onUpdateSpecimen = async (id: string) => {
    if (edit?.pathology_sample.id) {
      const res = await request(
        `pathology-section/${id}`,
        "POST",
        {
          pathology_sample_id: edit?.pathology_sample.id,
          pathology_outcome_id: edit.pathology_outcome.id,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (res) {
        getLog();
        setEdit(null);
      }
    } else {
      const res = await request(
        "profile/pathology-sample",
        "POST",
        {
          name: edit?.pathology_sample.name,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (res) {
        const result = await request(
          `pathology-section/${id}`,
          "POST",
          {
            pathology_sample_id: res.data.id,
            pathology_outcome_id: edit?.pathology_outcome.id,
          },
          {
            Authorization: `Bearer ${token}`,
          }
        );
        if (result) {
          getLog();
          setEdit(null);
        }
      }
    }
  };

  const onDeleteSpecimen = async (id: string) => {
    const res = await request(`pathology-section/${id}/delete`, "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    if (res) {
      getLog();
    }
  };

  return (
    <>
      <TitleSection>Pathology specimens:</TitleSection>
      {loading ? (
        <SpinnerWrapper>
          <Spinner color={baseTheme.colors.main} />
        </SpinnerWrapper>
      ) : (
        <ColumnOutcome direction={false}>
          <>
            {uncheckedSpecimens !== null && uncheckedSpecimens.length > 0 && (
              <TableRow>
                <StyledText></StyledText>
                <div
                  style={{
                    width: "45%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <StyledText>Check all specimen</StyledText>
                </div>
                <SwitchCell>
                  <ToggleSwitch
                    isChecked={false}
                    onChange={checkAllSpecimens}
                  />

                  <div style={{ opacity: 0, margin: "0 4px" }}>
                    <EditIcon
                      width={20}
                      height={20}
                      color={baseTheme.colors.black}
                    />
                  </div>
                </SwitchCell>
              </TableRow>
            )}

            {caseSpecimens.length > 0 ? (
              caseSpecimens.map((specimen) =>
                edit && edit.id === specimen.id ? (
                  <TableRow key={specimen.id}>
                    <AutoCompleteInput
                      isOpDetails={true}
                      allSupervisors={specimens}
                      value={edit.pathology_sample.name}
                      onChange={(value) => handleSpecimenNameChange(value)}
                    />
                    <Select
                      placeholder={edit.pathology_outcome.name}
                      isEditField={true}
                      isOpDetails={true}
                      options={outcome.map((option) => ({
                        value: option.id,
                        label: option.name,
                      }))}
                      onChange={(value) => handleOutcomeChange(value)}
                    />
                    <EditButtons>
                      <div
                        style={{ paddingRight: "10px", paddingTop: "3px" }}
                        onClick={() => onUpdateSpecimen(edit.id.toString())}
                      >
                        <CheckIcon
                          color={baseTheme.colors.black}
                          width={25}
                          height={25}
                        />
                      </div>
                      <CloseButton
                        onClick={() => onDeleteSpecimen(edit.id.toString())}
                      >
                        <CloseIcon />
                      </CloseButton>
                      <Separator />
                      <div onClick={() => setEdit(null)}>
                        <Cancel
                          color={baseTheme.colors.black}
                          width={20}
                          height={20}
                        />
                      </div>
                    </EditButtons>
                  </TableRow>
                ) : (
                  <TableRow key={specimen.id}>
                    <StyledText>{specimen.pathology_sample.name}</StyledText>
                    <StyledText>{specimen.pathology_outcome.name}</StyledText>

                    <SwitchCell>
                      <ToggleSwitch
                        isChecked={specimen.checked}
                        onChange={() =>
                          checkPathSpecimen(
                            specimen.id.toString(),
                            specimen.pathology_outcome.id.toString(),
                            specimen.checked
                          )
                        }
                      />
                      <Separator />
                      <div onClick={() => setEdit(specimen)}>
                        <EditIcon
                          width={20}
                          height={20}
                          color={baseTheme.colors.black}
                        />
                      </div>
                    </SwitchCell>
                  </TableRow>
                )
              )
            ) : (
              <>N/A</>
            )}
          </>
        </ColumnOutcome>
      )}
    </>
  );
}
