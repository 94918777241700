import CheckOperationFields from "components/Modal/CheckOperationFields";
import Modal from "components/Modal/Modal";
import { ReactNode } from "react";

interface IOpCaseFields {
  title: string;
  isOpen: boolean;
  icon?: ReactNode;
  settingsProfile: () => void;
  dataSettingsProfile: any;
  onRequestClose: () => void;
}

export default function OpCaseFields({
  isOpen,
  onRequestClose,
  dataSettingsProfile,
  title,
  settingsProfile,
  icon,
}: IOpCaseFields) {
  return (
    <Modal
      isOpen={isOpen}
      icon={icon}
      onRequestClose={onRequestClose}
      children={
        <CheckOperationFields
          dataSettingsProfile={dataSettingsProfile}
          settingsProfile={settingsProfile}
        />
      }
      title={title}
    ></Modal>
  );
}
