import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import FooterNav from "./FooterNav";
import { baseTheme } from "styles/theme";

const FooterWrapper = styled.div`
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  padding: 50px 0 30px;
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url(https://img1.wsimg.com/isteam/stock/1YNpAdQ/:/cr=t:12.5%25,l:0%25,w:100%25,h:75%25/rs=w:600,h:300,cg:true);
  @media (max-width: 992px) {
    padding: 0;
    background-size: 300%;
  }
  @media (max-width: 720px) {
    background-size: 200%;
  }
  @media (max-width: 540px) {
    background-size: 400%;
  }
`;
const FooterStyled = styled.footer`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 1;
  position: relative;
  padding: 50px 0 30px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1921px) {
    max-width: 1140px;
  }
  @media (min-width: 2500px) {
    max-width: 1440px;
  }
  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }
`;
const FooterOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-color: rgba(40, 40, 40, 0.8705882352941177);
  mix-blend-mode: multiply;
  z-index: 0;
`;
const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 960px) {
    padding: 20px 0 0 15px;
  }
`;

const LinkStyled = styled(Link)`
  color: ${baseTheme.colors.white};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 5px;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 32px;
  }

  @media (min-width: 2500px) {
    font-size: 24px;
    line-height: 40px;
  }
`;

const Title = styled.div`
  font-size: 15px;
  line-height: 26px;
  color: ${baseTheme.colors.main};
  font-weight: 500;
  text-transform: uppercase;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 32px;
  }

  @media (min-width: 2500px) {
    font-size: 24px;
    line-height: 40px;
  }
`;
const TextContainer = styled.div`
  width: 75%;
`;
const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${baseTheme.colors.white};
  line-height: 26px;
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
    line-height: 40px;
  }
`;
const LinksContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
  }
`;
const Copyright = styled.div`
  margin-top: 40px;
  border-top: 1px solid #303030;
  text-align: center;
  color: ${baseTheme.colors.white};
  padding-top: 40px;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  z-index: 1;
  @media (max-width: 960px) {
    padding: 20px;
  }
  @media (min-width: 1921px) {
    font-size: 18px;
    line-height: 29px;
  }
  @media (min-width: 2500px) {
    font-size: 24px;
    line-height: 40px;
  }
`;
function Footer() {
  return (
    <FooterWrapper>
      <FooterOverlay />
      <FooterStyled>
        <Row>
          <TextContainer>
            <Text>
              Revolutionize the way you log your surgical cases. Discover rapid
              data entry using your smart device camera
            </Text>
          </TextContainer>
        </Row>
        <LinksContainer>
          <Row>
            <Title>Navigation</Title>
            <FooterNav />
          </Row>
          <Row>
            <Title>Other links</Title>
            <LinkStyled to="/terms-and-conditions">
              Terms and Conditions
            </LinkStyled>
            <LinkStyled to="/privacy-policy">Privacy Policy</LinkStyled>
          </Row>
        </LinksContainer>
      </FooterStyled>
      <Copyright>COPYRIGHT © 2024 SNAP LOG - ALL RIGHTS RESERVED.</Copyright>
    </FooterWrapper>
  );
}

export default Footer;
