import styled from "styled-components";
import Select from "components/Select";
import Button from "components/Buttons/Button";
import { DataItem, IOutcome, Specimen } from "interfaces";
import { useAxios } from "hooks/useAxios";
import { useEffect, useState } from "react";
import { getToken } from "helpers";
import { TitleSection } from "styles/components";
import AutoCompleteInput from "components/AutoCompleteInput";
import Toast from "components/Toast";

const OutcomesContent = styled.div`
  padding: 10px;
  width: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: visible;
  overflow-x: hidden;
  height: 450px;
  @media (min-width: 1921px) {
    width: 450px;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 20px;
  width: 100%;
`;

const SpecimensWrapper = styled.div`
  padding: 10px;
  width: 350px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  @media (min-width: 1921px) {
    width: 450px;
  }
`;

const SpecimenItem = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

interface IOutcomes {
  title?: string;
  options: IOutcome[];
  outcomeLoading?: boolean;
  id?: string;
  specimens?: Specimen[];
  queryFieldName: string;
  currentOutcomes: IOutcome[];
  getUpdateLog: () => void;
  log?: any;
}

const OutcomeModal: React.FC<IOutcomes> = ({
  options,
  id,
  currentOutcomes,
  queryFieldName,
  specimens,
  getUpdateLog,
  log,
}) => {
  const token = getToken();
  const { request, loading, error } = useAxios();
  const [showButtonStates, setShowButtonStates] = useState<
    Record<number, boolean>
  >({});
  const [item, setItem] = useState<number>();
  const [amountSpecimens, setAmountSpecimens] = useState(0);
  const [logSpecimens, setLogSpecimens] = useState([]);
  const [newSpecimen, setNewSpecimen] = useState({ name: "", index: 0 });
  const [selectedSpecimens, setSelectedSpecimens] = useState<
    { outcomeId?: number; specimenId?: string; specimenName?: string }[]
  >([]);
  const [numSpecimens, setNumSpecimens] = useState<number>(1);

  useEffect(() => {
    setAmountSpecimens(log.pathology_sections.length);
    setLogSpecimens(log.pathology_sections);
  }, [log.pathology_sections]);

  const handleSpecimenNameChange = (index: number, value: string) => {
    const specimen = specimens?.find((item) => item.name === value);

    if (specimen) {
      setSelectedSpecimens((prevState) => {
        const updated = [...prevState];
        updated[index] = {
          ...updated[index],
          specimenName: value,
          specimenId: specimen.id,
        };
        setShowButtonStates((prev) => ({ ...prev, [index]: false }));
        return updated;
      });
    } else {
      setShowButtonStates((prev) => ({ ...prev, [index]: true }));
      setNewSpecimen({ name: value, index: index });
      setSelectedSpecimens((prevState) => {
        const updated = [...prevState];
        updated[index] = { ...updated[index], specimenName: value };
        return updated;
      });
    }
  };

  const addNewSpecimen = async () => {
    const res = await request(
      "profile/pathology-sample",
      "POST",
      {
        name: newSpecimen.name,
      },
      {
        Authorization: `Bearer ${token}`,
      }
    );
    if (res?.data) {
      setSelectedSpecimens((prevState) => {
        const updated = [...prevState];
        updated[newSpecimen.index] = {
          ...updated[newSpecimen.index],
          specimenId: res.data.id,
          specimenName: res.data.name,
        };
        return updated;
      });
    }
    setShowButtonStates((prev) => ({ ...prev, [newSpecimen.index]: false }));
  };

  const handleOutcomeChange = (index: number, value: number) => {
    setSelectedSpecimens((prevState) => {
      const updated = [...prevState];
      updated[index] = { ...updated[index], outcomeId: value };
      return updated;
    });
  };

  const getAvailableSpecimenOptions = (currentIndex: number) => {
    const usedSpecimens = selectedSpecimens
      ?.filter((_, index) => index !== currentIndex)
      .map((specimen) => specimen.specimenName);

    const logUsedSpecimens = logSpecimens?.map(
      (specimen: DataItem) => specimen?.pathology_sample.name
    );

    return specimens?.filter(
      (specimen) =>
        !usedSpecimens.includes(specimen.name) &&
        !logUsedSpecimens.includes(specimen.name)
    );
  };

  const addOutcome = async () => {
    if (!item) return;

    const formData = new FormData();
    const idsOutcomes = [...currentOutcomes.map((outcome) => outcome.id), item];

    idsOutcomes.forEach((id, index) => {
      formData.append(`${queryFieldName}[${index}]`, id.toString());
    });

    await request(`operation-case/${id}`, "POST", formData, {
      Authorization: `Bearer ${token}`,
    });
    getUpdateLog();
  };

  const saveSpecimensData = async () => {
    if (selectedSpecimens.length === 0) {
      await request(
        `operation-case/${id}`,
        "POST",
        {
          pathology_sections: [],
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      getUpdateLog();
    } else {
      const formData = new FormData();
      const filteredSections = log.pathology_sections.filter(
        (section: any) => section !== null
      );

      filteredSections.forEach((section: any, index: number) => {
        console.log('isChecked',section.checked)
        const isChecked = section.checked === true ? '1' : '0';
        console.log('isChecked',section.checked)
        formData.append(
          `pathology_sections[${index}][pathology_outcome_id]`,
          section.pathology_outcome.id
        );
        formData.append(
          `pathology_sections[${index}][pathology_sample_id]`,
          section.pathology_sample.id
        );

        formData.append(`pathology_sections[${index}][checked]`, isChecked);
      });

      let index = filteredSections.length;

      selectedSpecimens.forEach((specimen: any) => {
        formData.append(
          `pathology_sections[${index}][pathology_outcome_id]`,
          specimen.outcomeId
        );
        formData.append(
          `pathology_sections[${index}][pathology_sample_id]`,
          specimen.specimenId
        );
        index++;
      });

      await request(`operation-case/${id}`, "POST", formData, {
        Authorization: `Bearer ${token}`,
      });
      setSelectedSpecimens([]);
      getUpdateLog();
    }
  };

  const renderSpecimens = () =>
    new Array(numSpecimens).fill(null).map((_, index) => {
      const specimen = selectedSpecimens[index] || {};
      const availableSpecimenOptions = getAvailableSpecimenOptions(index);

      return (
        <SpecimenItem key={index}>
          <TitleSection>New specimen</TitleSection>
          <AutoCompleteInput
            isAddButton={showButtonStates[index] || false}
            value={specimen.specimenName || ""}
            placeholder="Enter specimen"
            allSupervisors={availableSpecimenOptions}
            onAddClick={addNewSpecimen}
            onChange={(value) => handleSpecimenNameChange(index, value)}
            isOpDetails={false}
            selectedSpecimens={selectedSpecimens}
          />

          <div style={{ paddingTop: "20px" }}>
            <Select
              placeholder="Select pathology outcome"
              options={options.map((option) => ({
                value: option.id,
                label: option.name,
              }))}
              onChange={(value: number) => handleOutcomeChange(index, value)}
            />
          </div>
        </SpecimenItem>
      );
    });

  const placeholder =
    queryFieldName === "pathology_outcome"
      ? "Select pathology Outcome"
      : "Select complication Outcome";

  return (
    <OutcomesContent>
      {["pathology_outcome", "complication_outcome"].includes(
        queryFieldName
      ) ? (
        <>
          <Select
            options={options.map((option) => ({
              value: option.id,
              label: option.name,
            }))}
            placeholder={placeholder}
            onChange={(value: number) => setItem(value)}
          />
          <ButtonWrapper>
            <Button loading={loading} onClick={addOutcome} text="Save" />
          </ButtonWrapper>
        </>
      ) : (
        <SpecimensWrapper>
          <div style={{ position: "relative", zIndex: 20, width: "100%" }}>
            <Select
              placeholder="1"
              options={Array.from({ length: 13 - amountSpecimens }, (_, i) => ({
                label: `${i === 0 ? "No specimens" : i}`,
                value: i,
              }))}
              onChange={(value: number) => setNumSpecimens(value)}
            />
          </div>
          {renderSpecimens()}
          <ButtonWrapper>
            <Button loading={loading} onClick={saveSpecimensData} text="Save" />
          </ButtonWrapper>
        </SpecimensWrapper>
      )}
      {error && <Toast error={error} />}
    </OutcomesContent>
  );
};

export default OutcomeModal;
