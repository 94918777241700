import { getFromLocalStorageUser, IUserLStorage } from "helpers";
import { SubButton, Text } from "./subManagerStyles";
import Button from "components/Buttons/Button";
import { baseTheme } from "styles/theme";
interface IWebTextSub {
  confirmHandlePayment: () => void;
}

export default function WebTextSub({ confirmHandlePayment }: IWebTextSub) {
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const {  subscribe_expire_date } = user;


  return (
    <>
      <Text>Your Subscription is active.</Text>
      <Text>Extension date is {subscribe_expire_date}.</Text>
      {/* <Text>
            If you want to cancel your Subscription, please click the "Cancel my
            subscription" button.
          </Text> */}
      <SubButton>
        <Button
          fontWeight={500}
          fontSize="14px"
          onClick={confirmHandlePayment}
          bg={baseTheme.colors.main}
          text={"Cancel my Subscription"}
        />
      </SubButton>
    </>
  );
}
