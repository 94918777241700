import React, { useEffect, useState } from "react";
import { useAxios } from "hooks/useAxios";
import {
  IUserLStorage,
  getFromLocalStorageUser,
  getToken,
  saveStatusMember,
  saveToLocalStorageUser,
} from "helpers";
import MainLayout from "layouts/MainLayout";
import Toast from "components/Toast";
import ProfileMainFields from "components/ProfileMainFields";
import { SettingsArrayType } from "interfaces";
import ProfileUserinfo from "./components/ProfileUserinfo";

import { baseTheme } from "styles/theme";
import { styled } from "styled-components";
import CustomFields from "./components/CustomFieldsList";
import { TitleSection } from "styles/components";
// import Spinner from "components/Spinners/ButtonSpinner";
// import { SpinnerWrapper } from "./components/CustomFields/style";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 10px;
  justify-content: center;
  flex-direction: row;
  border-radius: 10px;
  height: 100%;
  position: relative;
  background-color: ${baseTheme.colors.white};
  padding: 10px;
  justify-content: flex-start;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ProfileMainFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const Container = styled.div`
  display: flex;
  width: 100%;

  & > :first-child {
    flex: 0 0 50%;
  }

  & > :nth-child(2),
  & > :nth-child(3) {
    flex: 1;
  }

  @media (max-width: 1025px) {
    flex-direction: column;
    align-items: center;
  }
`;

function Profile() {
  const user: IUserLStorage = getFromLocalStorageUser("user");
  const { role } = user || {};
  const { request, error, loading } = useAxios();
  const [, setSettingLoader] = useState(false);
  const [dataSettingsProfile, setDataSettingsProfile] =
    useState<SettingsArrayType>([]);
  const token = getToken();
  useEffect(() => {
    settingsProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getUserInfo() {
    const res = await request("profile/user", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    saveToLocalStorageUser("user", res?.data || null);
    saveStatusMember(res?.data?.subscribe_status || null);
  }

  async function settingsProfile() {
    setSettingLoader(true);
    const res = await request("profile/operation-case/settings", "GET", null, {
      Authorization: `Bearer ${token}`,
    });
    setDataSettingsProfile(res?.data || null);
    getUserInfo();
    setSettingLoader(false);
  }
  return (
    <MainLayout
      children={
        <>
          <Wrapper>
            {(role === "superadmin" || role === "admin") && <ProfileUserinfo />}
            {role === "user" && (
              <Container>
                <ProfileUserinfo settingsProfile={settingsProfile} />
                {/* {settingLoader ? (
                  <SpinnerWrapper>
                    <Spinner />
                  </SpinnerWrapper>
                ) : ( */}
                <>
                  <ProfileMainFieldsWrapper>
                    <TitleSection>Main fields</TitleSection>
                    <ProfileMainFields
                      dataSettingsProfile={dataSettingsProfile}
                      loading={loading}
                      settingsProfile={settingsProfile}
                    />
                  </ProfileMainFieldsWrapper>

                  <CustomFields
                    settingsProfile={settingsProfile}
                    dataSettingsProfile={dataSettingsProfile}
                  />
                </>
                {/* )} */}
              </Container>
            )}
            {error && <Toast error={error} />}
          </Wrapper>
        </>
      }
    />
  );
}

export default Profile;
