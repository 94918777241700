import React from "react";

import OCaseFields from "assets/icons/OperationCaseFields";
import CustomBooleanField from "./CustomFields/CustomBinaryField";
import CustomToggleField from "./CustomFields/CustomToggleField";
import CustomTextField from "./CustomFields/CustomTextField";
import { baseTheme } from "styles/theme";
import Modal from "components/Modal/Modal";
import CustomOptionField from "./CustomFields/CustomOptionField";

interface CustomFieldProps {
  showModal: boolean;
  onCloseModal: (isOpen: boolean) => void;
  data: any;
  getCustomFields: () => {};
  error?: any;
  setData?: any;
}

const CustomField: React.FC<CustomFieldProps> = ({
  showModal,
  onCloseModal,
  data,
  setData,
  getCustomFields,
}) => {
  const renderField = () => {
    switch (data?.type) {
      case "boolean":
        return (
          <CustomBooleanField
            data={data}
            setData={setData}
            closeModal={onCloseModal}
            getCustomFields={getCustomFields}
          />
        );
      case "toggle":
        return (
          <CustomToggleField
            data={data}
            setData={setData}
            closeModal={onCloseModal}
            getCustomFields={getCustomFields}
          />
        );
      case "text":
        return (
          <CustomTextField
            data={data}
            setData={setData}
            closeModal={onCloseModal}
            getCustomFields={getCustomFields}
          />
        );
      case "options":
        return (
          <CustomOptionField
            data={data}
            setData={setData}
            closeModal={onCloseModal}
            getCustomFields={getCustomFields}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        icon={
          <OCaseFields color={baseTheme.colors.main} width={32} height={32} />
        }
        isOpen={showModal}
        onRequestClose={() => {
          onCloseModal(false);
          // getCustomFields();
        }}
        children={<div style={{width:'100%', padding:"0 20px"}}>{renderField()}</div>}
        title={""}
      ></Modal>
    </>
  );
};

export default CustomField;
